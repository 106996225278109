import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchStateList = createAsyncThunk(
  "state/fetchList",
  async () => {
    try {
      const res = await UserService.get.StateList();
      return res.data && res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const { reducer: StateReducer } = createSlice({
  name: "state",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchStateList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default StateReducer;
