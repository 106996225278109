import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer ">
        <div className="d-sm-flex justify-content-center justify-content-sm-between ">
          <h5 className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright © 2024.{" "}
            <a href="https://lofaz.com/" target="_blank" rel="noreferrer">
              Lofaz.
            </a>{" "}
            All rights reserved.
          </h5>
        </div>
      </footer>
    );
  }
}

export default Footer;
