import React from "react";

const AddButton = ({ handler, label }) => {
  return (
    <button
      className="btn btn-dark d-flex justify-content-center align-items-center flex-wrap mx-1"
      onClick={handler}
    >
      <i className="fa fa-plus mr-2" />
      <span style={{ whiteSpace: "nowrap" }}>{label}</span>
    </button>
  );
};

export default AddButton;
