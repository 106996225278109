import React from "react";
import { Modal } from "react-bootstrap";
import { ImportCSVModalForm } from "./ModalForm";

export function ImportCSVVendorModal({ show, onHide }) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm" className="example-modal-sizes-title-sm-csv">
          Import Vendor Data from CSV
        </Modal.Title>
      </Modal.Header>
      <ImportCSVModalForm onHide={onHide} />
    </Modal>
  );
}
