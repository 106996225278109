import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchSuggestedCategoryList } from "../../../../../redux/suggestedCategorySlice";
import { photoSchema } from "../../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newSuggestedCategorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export const ModalForm = withRouter(
  ({ submitHandler, actionsLoading, onHide, preFill, history }) => {
    const dispatch = useDispatch();
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            (preFill && preFill.values) || {
              photo: "",
              name: "",
            }
          }
          validationSchema={
            preFill
              ? newSuggestedCategorySchema
              : newSuggestedCategorySchema.concat(photoSchema)
          }
          onSubmit={async (values) => {
            const res = await (preFill
              ? submitHandler(preFill.id, values)
              : submitHandler(values));
            onHide();
            if (res.status === 200) dispatch(fetchSuggestedCategoryList());
          }}
        >
          {({ handleSubmit, values, handleChange, setFieldValue }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form">
                  <div className="form-group row justify-content-center flex-column align-items-center">
                    {values.photo && (
                      <img src={values.photo} alt="" width="80" />
                    )}
                    <div className="col">
                      <label htmlFor="photo">Image</label>
                      <input
                        className="form-control-file"
                        type="file"
                        name="photo"
                        id="photo"
                        onChange={(event) => {
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className="justify-content-around">
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-elevate"
                >
                  {preFill ? "Save" : "Create"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
);
