// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchCategoryList } from "../../../../redux/categorySlice";

// Validation schema
const newCategorySchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  type: Yup.string().required(),
});

export const ModalForm = withRouter(
  ({ submitHandler, actionsLoading, onHide, preFill, history }) => {
    const dispatch = useDispatch();
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            (preFill && preFill.values) || {
              title: "",
              type: "story",
            }
          }
          validationSchema={newCategorySchema}
          onSubmit={async (values) => {
            const res = await (preFill
              ? submitHandler(preFill.id, values)
              : submitHandler(values));
            onHide();
            if (res.status === 200) dispatch(fetchCategoryList());
          }}
        >
          {({ handleSubmit, values, handleChange }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-4">
                      <label htmlFor="title">Title</label>
                      <input
                        className="form-control"
                        name="title"
                        placeholder="Title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Type */}
                    <div className="col-lg-4">
                      <label htmlFor="type">Category Type</label>
                      <select
                        name="type"
                        id="type"
                        className="form-control"
                        value={values.type}
                        onChange={handleChange}
                      >
                        <option value="story">Story</option>
                        <option value="post">Post</option>
                      </select>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-elevate"
                >
                  {preFill ? "Save" : "Create"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
);
