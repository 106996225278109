import React, { Component } from "react";
import { handleDelete, wrapDataTable } from "../../../utils";
import AddButton from "../../common/AddButton";
import Container from "../../common/Container";
import StatusSwitch from "../../common/StatusSwitch";

const Actions = ({ handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <button className="btn btn-primary p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

const PostDataTable = wrapDataTable("Post");

class Post extends Component {
  render() {
    return (
      <Container>
        <PostDataTable
          utilButtons={[
            <AddButton
              label="New Post"
              handler={() => this.props.history.push("post/new")}
            />,
          ]}
          tableName="Posts"
          searchByList={[
            { label: "Title", value: "title" },
            { label: "Description", value: "description" },
            {
              label: "Status",
              value: (data) => (data["status"] ? "Active" : "Inactive"),
            },
          ]}
          columnData={[
            {
              name: "Image",
              transform: (data) => (
                <img src={data["photo"]} alt={data["title"]} />
              ),
            },
            { name: "Title", value: "title" },
            { name: "Description", value: "description" },
            {
              name: "Status",
              transform: (data) => <StatusSwitch data={data} ofWhat="Post" />,
            },
            {
              name: "Actions",
              transform: (data) => (
                <Actions
                  handleEdit={() =>
                    this.props.history.push({
                      pathname: "/post/new",
                      state: {
                        id: data["_id"],
                        values: {
                          title: data["title"],
                          description: data["description"],
                          photo: data["photo"],
                          status: data["status"],
                        },
                      },
                    })
                  }
                  handleDelete={() => handleDelete(data && data["_id"], "Post")}
                  handleChangeStatus={() => null}
                />
              ),
            },
          ]}
          defaultSortBy="Created on"
          defaultSortByFun={(a, b) =>
            new Date(a["createdAt"]) - new Date(b["createdAt"])
          }
        />
      </Container>
    );
  }
}

export default Post;
