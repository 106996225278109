import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchCatalogList } from "../../../../redux/catalogSlice";

// Validation schema
const newCatalogSchema = Yup.object().shape({
  title: Yup.string().required(),
  photo: Yup.mixed().required("A file is required"),
  active: Yup.boolean().required(),
});

export const ModalForm = withRouter(
  ({ submitHandler, actionsLoading, onHide, preFill, history }) => {
    const dispatch = useDispatch();

    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            (preFill && preFill.values) || {
              title: "",
              photo: "",
              active: true,
            }
          }
          validationSchema={newCatalogSchema}
          onSubmit={async (values) => {
            console.log(values);
            const res = await (preFill
              ? submitHandler(preFill.id, values)
              : submitHandler(values));
            if (res.status === 200) {
              onHide();
              dispatch(fetchCatalogList());
            }
          }}
        >
          {({ values, handleSubmit, handleChange, setFieldValue, isValid }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* Title */}
                    <div className="col-lg-4">
                      <label htmlFor="title">Title</label>
                      <input
                        className="form-control"
                        name="title"
                        placeholder="Title"
                        id="title"
                        onChange={handleChange}
                        value={values.title}
                      />
                    </div>
                    <div className="col-lg-4 d-flex">
                      <div>
                        <label htmlFor="photo">Photo</label>
                        <input
                          type="file"
                          className="form-control-file"
                          name="photo"
                          id="photo"
                          onChange={(event) => {
                            setFieldValue(
                              "photo",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                      {values.photo && (
                        <img
                          src={values.photo}
                          alt=""
                          width="150"
                          className="m-3"
                        />
                      )}
                    </div>
                    <div className="col-lg-4">
                      <label for="customSwitch1">Status</label>
                      <div className="custom-control custom-switch">
                        <Field
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          name="active"
                          checked={values.active}
                          onChange={handleChange}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={!isValid}
                >
                  {preFill ? "Save" : "Create"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
);
