import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../../utils";
import Container from "../../common/Container";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { API_URL } from "../../../utils";
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';
import AddButton from '../../common/AddButton';
import { saveAs } from "file-saver";
import XLSX from "xlsx";

const ViewCatalogButton = ({ viewUrl, handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <a
        className="btn btn-primary p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View"
      >
        <i className="fas fa-eye" />
      </a>
      <button className="btn btn-danger p-2" title="Delete" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
      <button className="btn btn-primary p-2" title="Edit" onClick={handleEdit}>
        <i className="fas fa-pen" />
      </button>
    </div>
  </div>
);

const StatusSwitch = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      color="primary"
    />
  );
};

export const getCatalogTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}catalog/admin`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], total_catalog: 0 };
  }
};

class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    this.fetchData(currentPage, limit, search, sort, sortBy);
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getCatalogTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetch",data);
      this.setState({ orderData: data.data, totalRows: data.total_catalog, fetched: true });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  };

    
downloadAsXls = () => {
  const { data } = this.state;
  const columns = this.columns.map(col => ({ name: col.name, selector: col.selector }));
  const tableName = 'VendorData'; // Customize this as needed
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: tableName,
    Subject: "Exported Data",
    Author: "Your Company",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Sheet1");
  // Prepare header
  const header = columns.map(col => col.name);
  const ws_data = [header];
  // Prepare data rows
  data.forEach(row => {
    const rowData = columns.map(col => row[col.selector] || ''); // Adjust based on your data structure
    ws_data.push(rowData);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Sheet1"] = ws;
  // Convert to binary string and save
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${tableName}_${new Date().toISOString().slice(0, 10)}.xlsx`
  );
};

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
    });
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      //console.log(limit);
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  };


  handleStatusChange = async (id, newStatus) => {
    // Make API call to update the status on the server
    try {
      await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
        headers: authHeader(),
      });
      const updatedData = this.state.orderData.map(item =>
        item._id === id ? { ...item, active: newStatus } : item
      );
      this.setState({ orderData: updatedData });
    } catch (error) {
      console.error("Error updating catalog status:", error);
    }
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  }

  columns = [
    { name: "SL", cell: (row, index) => <p className='text-center'>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },
    
    {
      name: "Catalog Name", cell: (row) => {
        //console.log(data);
        return (<p>{row ? row.title : ''}</p>)
      }, width: '150px',
      sortField: 'title',
      sortable: true,
    },
    {
      name: "Business Name", cell: (data) => {
        //console.log(data);
        return (<p>{data["shop"] ? data["shop"]["business_name"] : ''}</p>)
      }, width: '300px',
      // sortField: 'business_name',
      // sortable: true,
    },
    {
      name: "Total Products", cell: (data) => {
        //console.log(data);
        return (<p>{data["total_product"]}</p>)
      }, width: '150px',
      sortField: 'total_product',
      sortable: true,
    },
    
    {
      name: "Created On",
      selector: (data) => {
        const date = new Date(data.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'createdAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      button: true,
      minWidth: '200px',
      // cell: (row) => (
      //   <div onClick={() => this.handleSortClick('createdAt')}>
      //     {new Date(row.createdAt).toLocaleString('en-US', {
      //       weekday: 'short',
      //       month: 'short',
      //       day: '2-digit',
      //       year: 'numeric',
      //       hour: 'numeric',
      //       minute: '2-digit',
      //       hour12: true
      //     }).replace(",", "")}
      //   </div>
      // ),
    },
    {
      name: "Updated On",
      cell: (data) => {
        const date = new Date(data.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'updatedAt',
      sortable: true,
    },
    {
      name: "Status",
      cell: (data) => (
        <StatusSwitch
          checked={data.active}
          onChange={(event) => this.handleStatusChange(data._id, event.target.checked)}
        />
      ),
      width: '150px'
    },
    {
      name: 'Actions',
      cell: (data) => (
        <div style={{ gap: "7px" }} className="d-flex">
          <ViewCatalogButton
            handleDelete={() =>
              handleDelete(
                data["_id"],
                "Catalog",
                async () => {
                  const { currentPage, limit } = this.state;
                  const data = await getCatalogTableConfig(currentPage, limit);
                  this.setState({ orderData: data.catalog, totalRows: data.total_catalog });
                }
              )
            }
          />
        </div>
      ),
    }
  ];

  render() {
    const { orderData, fetched, currentPage, search, limit, totalRows } = this.state;

    const { history } = this.props;

    console.log(orderData);


    

    return (
      <Container>
        
        <div className="mb-3 d-flex mt-4 justify-content-between text-center">

          
            <div className="mx-2"><h3>Catalogs</h3></div>
            <div className="flex-grow col-6 mx-2" style={{ minWidth:'350px' }}>
              <input
                type="text"
                className="form-control"
                aria-label="select search by"
                placeholder="Search"
                value={search}
                onChange={this.handleSearchChange}
              />
            </div>
        
          
        </div>
        <DataTable
          // title="Catalogs"
          searchByList={[
            { label: "Catalog Name", value: "title" },
          
            {
              label: "Vendor Business Name",
              value: ({ data }) => (data ? data["shop"]["business_name"] : ""),
            },
          ]}
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ data }) => (data ? data["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getCatalogTableConfig(history)}


          data={orderData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50]} // Options for rows per page
          paginationTotalRows={totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default Catalog;

