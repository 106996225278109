import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchStoryList } from "../../../../../redux/storySlice";

// Validation schema
const importCSVSchema = Yup.object().shape({
  file: Yup.mixed().required("CSV file is required"),
});

export const ImportCSVModalForm = withRouter(
  ({ onSubmit, actionsLoading, onHide }) => {
    const dispatch = useDispatch();
    return (
      <>
        <Formik
          initialValues={{
            file: "",
          }}
          validationSchema={importCSVSchema}
          onSubmit={async (values) => {
            const formData = new FormData();
            formData.append("file", values.file);

            const res = await dispatch(fetchStoryList(formData));
            onHide();
            // Add further actions based on response if needed
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form">
                  <div className="form-group">
                    <label htmlFor="file">Select CSV File:</label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="file"
                      name="file"
                      accept=".csv"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className="justify-content-around">
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-dark btn-elevate"
                >
                  Import
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
);
