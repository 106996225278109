import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../../services/user.service"
import { ModalForm } from "./ModalForm";

export function NewBannerModal({ show, onHide, preFill }) {
    return (
        <Modal
            size="md"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg" className="model-title">
                    {preFill ? "Edit" : "Add New"} Banner
                </Modal.Title>
            </Modal.Header>

            <ModalForm
                onHide={onHide}
                preFill={preFill}
                submitHandler={preFill ? UserService.putBanner : UserService.postBanner}
            />
        </Modal>
    );
}
