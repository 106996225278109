import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchCustomerList = createAsyncThunk(
  "customer/fetchList",
  async () => {
    try {
      return await UserService.get.CustomerList();
    } catch (error) {
      console.log(error);
    }
  }
);

const { reducer: CustomerReducer } = createSlice({
  name: "customer",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchCustomerList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default CustomerReducer;
