import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Toggle from '@material-ui/core/Switch';
import { fetchNotificationList } from "../../../../redux/notificationSlice";
import { photoSchema } from "../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newNotificationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  repeat: Yup.boolean().required(),
});

/*"title": "Lofaz",
"description": "Bhai Kiase Ho :)",
"photo": "abcde",
"repeat": true,
"time":"13:36",
"start": "06/07/2021",
"end" */

export const ModalForm = ({ submitHandler, onHide, preFill }) => {
  const dispatch = useDispatch();

  const [photo, setPhoto] = React.useState("");

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        (preFill && preFill.values) || {
          title: "",
          description: "",
          photo: "",
          repeat: true,
          time: "",
          start: "",
          end: "",
          imgType: "file",
        }
      }
      // validationSchema={
      //   preFill
      //     ? newNotificationSchema
      //     : newNotificationSchema.concat(photoSchema)
      // }
      onSubmit={async (values) => {
        let partsOfDate = values.start.split("-");
        values.start = `${partsOfDate[2]}/${partsOfDate[1]}/${partsOfDate[0]}`;
        partsOfDate = values.end.split("-");
        values.end = values.repeat
          ? `${partsOfDate[2]}/${partsOfDate[1]}/${partsOfDate[0]}`
          : "";
        const res = await (preFill
          ? submitHandler(preFill.id, values)
          : submitHandler(values));
        if (res.status === 200) {
          onHide();
          dispatch(fetchNotificationList());
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue, isValid }) => (
        <>
          {(() => console.log(values))()}
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form className="form form-label-right">
              <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <Field
                    className="form-control"
                    name="country"
                    placeholder="Add country"
                    id="country"
                  />
              </div>
              <div>
                  <label htmlFor="status">Status</label>
                  <Toggle />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <> </>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
              disabled={!isValid}
            >
             Save
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
