import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchSuggestedProductList } from "../../../../../redux/suggestedProductSlice";
import { photoSchema } from "../../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newSuggestedProductSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  isActive: Yup.boolean().required(),
  mrp: Yup.string().required(),
  unit_type: Yup.string().required(),
  unit_count: Yup.string().required(),
});

export const ModalForm = withRouter(
  ({ submitHandler, onHide, preFill, history, catList, subCatList }) => {
    const dispatch = useDispatch();
    const [categoryId, setCategoryId] = React.useState(
      preFill ? preFill.category_id : catList[0] && catList[0]._id
    );
    const [subCategoryId, setSubCategoryId] = React.useState(
      preFill
        ? preFill.sub_category_id
        : subCatList[catList[0] && catList[0].name][0] &&
            subCatList[catList[0] && catList[0].name][0]._id
    );
    const [catLabel, setCatLabel] = React.useState(
      (() => {
        const catFromPreFill = catList.find(
          ({ _id }) => preFill && _id === preFill.category_id
        );
        return preFill
          ? catList.length > 0 && catFromPreFill && catFromPreFill.name
          : catList[0] && catList[0].name;
      })()
    );
    const [subCatLabel, setSubCatLabel] = React.useState(
      (() => {
        if (preFill) {
          const subCatFromPreFill =
            subCatList[catLabel] &&
            subCatList[catLabel].find(
              ({ _id }) => _id === preFill.sub_category_id
            );
          return subCatFromPreFill && subCatFromPreFill.name;
        }
        console.log(subCatList, catLabel);
        return (
          subCatList[catLabel] &&
          subCatList[catLabel][0] &&
          subCatList[catLabel][0].name
        );
      })()
    );
    const [subCatListOptions, setSubCatListOptions] = React.useState(
      subCatList[catLabel]
    );
    const handleCategoryChange = ({ target: { value } }) => {
      setCatLabel(value);
      setCategoryId(catList && catList.find(({ name }) => name === value)._id);
      setSubCatLabel(
        subCatList[value]
          ? subCatList[value][0]
            ? subCatList[value][0].name
            : ""
          : ""
      );
      setSubCategoryId(
        subCatList[value] && subCatList[value][0] && subCatList[value][0]._id
      );
      setSubCatListOptions(subCatList[value]);
    };
    const handleSubCategoryChange = ({ target: { value } }) => {
      setSubCatLabel(value);
      const options = subCatListOptions.find(({ name }) => name === value);
      setSubCategoryId(options ? options._id : "");
    };
    return (
      <Formik
        enableReinitialize={true}
        initialValues={
          (preFill && preFill.values) || {
            name: "",
            mrp: "",
            unit_type: "",
            unit_count: "",
            description: "",
            photo: "",
            isActive: true,
          }
        }
        validationSchema={
          preFill
            ? newSuggestedProductSchema
            : newSuggestedProductSchema.concat(photoSchema)
        }
        onSubmit={async (values) => {
          values = {
            ...values,
            category_id: categoryId,
            sub_category_id: subCategoryId,
          };
          const res = await (preFill
            ? submitHandler(preFill.id, values)
            : submitHandler(values));
          if (res.status === 200) {
            onHide();
            dispatch(fetchSuggestedProductList());
          }
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          isValid,
          errors,
        }) => {
          console.log(errors);
          return (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-12 col-lg-4">
                      <label htmlFor="category">Category</label>
                      <select
                        id="category"
                        className="text-capitalize form-control"
                        onChange={handleCategoryChange}
                        value={catLabel}
                      >
                        {catList &&
                          catList.length > 0 &&
                          catList.map((cat) => (
                            <option
                              value={cat.name}
                              className="text-capitalize"
                            >
                              {cat.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-12 col-lg-4">
                      <label htmlFor="subCategory">Sub Category</label>
                      <select
                        id="subCategory"
                        className="text-capitalize form-control"
                        onChange={handleSubCategoryChange}
                        value={subCatLabel}
                      >
                        {subCatListOptions &&
                          subCatListOptions.length > 0 &&
                          subCatListOptions.map(
                            (subCat) =>
                              subCat && (
                                <option
                                  value={subCat.name}
                                  className="text-capitalize"
                                >
                                  {subCat.name}
                                </option>
                              )
                          )}
                      </select>
                    </div>
                    <div className="col-12 col-lg-4">
                      <label for="customSwitch1">Status</label>
                      <div className="custom-control custom-switch">
                        <Field
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          name="isActive"
                          checked={values.isActive}
                          onChange={handleChange}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-lg-4">
                      <label htmlFor="name">Title</label>
                      <input
                        className="form-control"
                        name="name"
                        placeholder="Title"
                        id="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={handleChange}
                        value={values.description}
                      />
                    </div>
                    <div className="col-12 col-lg-4 d-flex">
                      <div>
                        <label htmlFor="photo">Photo</label>
                        <input
                          type="file"
                          className="form-control-file"
                          name="photo"
                          id="photo"
                          onChange={(event) => {
                            setFieldValue(
                              "photo",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                      {values.photo && (
                        <img
                          src={values.photo}
                          alt=""
                          width="150"
                          className="m-3"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-lg-4">
                      <label htmlFor="unit_type">Unit Type</label>
                      <input
                        className="form-control"
                        name="unit_type"
                        placeholder="Unit Type"
                        id="unit_type"
                        onChange={handleChange}
                        value={values.unit_type}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <label htmlFor="unit_count">Unit Count</label>
                      <input
                        className="form-control"
                        name="unit_count"
                        placeholder="Unit Count"
                        id="unit_count"
                        onChange={handleChange}
                        value={values.unit_count}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <label htmlFor="mrp">MRP</label>
                      <input
                        className="form-control"
                        name="mrp"
                        placeholder="MRP"
                        id="mrp"
                        onChange={handleChange}
                        value={values.mrp}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={!isValid}
                >
                  {preFill ? "Save" : "Create"}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    );
  }
);
