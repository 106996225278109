import React, { Component } from "react";
import { v1 as uuid } from "uuid";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { APP_URL } from "../../utils";

const TableNameAndSearch = ({
  title,
  searchByList,
  searchKeyword,
  handleSearchChange,
  searchBy,
  // handleSearchByChange,
}) => (
  <div className="d-flex align-items-center justify-between">
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center">
        <h4 className="card-title col-12 col-md-3 text-center text-md-left py-2 py-md-0 m-0">
          {title}
        </h4>
        <div className="col-12 col-xl-4 col-md-5">
          <input
            type="text"
            className="form-control"
            aria-label="select search by"
            placeholder="Search..."
            value={searchKeyword}
            onChange={handleSearchChange}
          />
          {/* <div className="input-group-append">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {searchBy.label}
            </button>
            <div className="dropdown-menu">
              {searchByList.map((option) => (
                <p
                  key={uuid()}
                  className="dropdown-item pointer"
                  onClick={() => handleSearchByChange(option)}
                >
                  {option.label}
                </p>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
);

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.numOfRows = 20;
    this.state = {
      searchKeyword: "",
      searchBy: props.searchByList[0],
      searchResult: [],
      currentPage: 0,
      sortBy: props.defaultSortBy,
      sortByFun: props.defaultSortByFun,
      sortOrder: "desc",
    };
  }

  handlePrevPage = () => {
    this.setState((state) => ({ currentPage: state.currentPage - 1 }));
  };
  handleNextPage = () => {
    this.setState((state) => ({ currentPage: state.currentPage + 1 }));
  };
  handlePageChange = (pageNo) => {
    this.setState({ currentPage: pageNo });
  };

  handleSearchByChange = (searchBy) => {
    this.setState({ searchBy });
  };
  handleSearchChange = ({ target: { value: searchKeyword } }) => {
    if (searchKeyword.length > 0) {
      const searchResult = this.props.dataList.filter((data) => {
        return this.props.searchByList.reduce((acc, searchBy) => {
          // console.log(acc, searchBy);
          return (
            acc ||
            (searchBy.exact
              ? (typeof searchBy.value === "string"
                  ? data[`${searchBy.value}`]
                  : searchBy.value(data)) == searchKeyword
              : String(
                  typeof searchBy.value === "string"
                    ? data[`${searchBy.value}`]
                    : searchBy.value(data)
                )
                  .toLowerCase()
                  .startsWith(searchKeyword.toLowerCase()))
          );
        }, false);
      });
      this.setState({
        searchKeyword,
        searchResult,
        totalPages: searchResult.length / this.numOfRows,
      });
    } else {
      this.setState((state) => ({
        searchKeyword,
        searchResult: [],
        totalPages: this.props.dataList.length / this.numOfRows,
      }));
    }
  };

  componentDidMount() {
    if (!this.props.fetched && this.props.fetchDataList) {
      this.props.fetchDataList();
    }
  }

  downloadAsXls = () => {
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: this.props.tableName,
      Subject: "Test",
      Author: "Red Stapler",
      CreatedDate: new Date(),
    };

    wb.SheetNames.push("Test Sheet");
    var ws_data = [];
    ws_data.push(this.props.columnData.map((val) => val.name));
    this.props.dataList
      .slice()
      .sort(
        this.state.sortByFun
          ? this.state.sortOrder === "desc"
            ? (a, b) => this.state.sortByFun(a, b) * -1
            : (a, b) => this.state.sortByFun(a, b)
          : () => 0
      )
      .forEach((data) => {
        const xlsData = {};
        for (let i of this.props.columnData) {
          xlsData[i.name] = i.xlFun ? i.xlFun(data) : data[i.value];
        }
        ws_data.push(Object.values(xlsData));
      });
    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Test Sheet"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${this.props.tableName}_${new Date().toDateString()}.xlsx`
    );
  };

  render() {
    const {
      state: {
        currentPage,
        searchBy,
        searchResult,
        searchKeyword,
        sortByFun,
        sortOrder,
        sortBy,
      },
      props: {
        columnData,
        searchByList,
        tableName,
        rowContainerClassFunction,
        dataList: rawDataList,
        fetched,
        utilButtons,
      },
      numOfRows,
      handlePrevPage,
      handleNextPage,
      handlePageChange,
      handleSearchByChange,
      handleSearchChange,
      downloadAsXls,
    } = this;
    const dataList = rawDataList || [];
    const startIdx = numOfRows * currentPage;
    const possibleEndIdx = numOfRows * currentPage + numOfRows;
    const endIdx =
      possibleEndIdx < dataList.length ? possibleEndIdx : dataList.length - 1;
    const totalPages = dataList.length / this.numOfRows;

    return (
      <>
        <div className="row justify-content-end p-2">
          {utilButtons}
          <button
            className="btn btn-primary col-12 col-md-1"
            onClick={downloadAsXls}
          >
            <i className="fa fa-file-download" />
          </button>
        </div>
        <div className="card">
          <div className="card-body">
            <TableNameAndSearch
              title={tableName}
              searchKeyword={searchKeyword}
              handleSearchChange={handleSearchChange}
              searchBy={searchBy}
              handleSearchByChange={handleSearchByChange}
              searchByList={searchByList}
            />
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table id="data-listing" className="table">
                    <thead>
                      <tr>
                        <th>SL</th>
                        {columnData.length &&
                          columnData.map((column) => (
                            <th key={uuid()}>
                              <div className="d-flex align-items-center">
                                <span className="">{column.name}</span>
                                {column.sortByFun && (
                                  <div
                                    className="d-flex ml-1 pointer"
                                    onClick={() =>
                                      this.setState(({ sortOrder }) => ({
                                        sortBy: column.name,
                                        sortByFun: column.sortByFun,
                                        sortOrder:
                                          sortOrder === "desc" ? "asc" : "desc",
                                      }))
                                    }
                                  >
                                    {sortOrder === "asc" ? (
                                      <i
                                        className="fa fa-sort-amount-asc"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: ".6rem",
                                          opacity:
                                            sortBy === column.name ? 1 : 0.5,
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-sort-amount-desc"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: ".6rem",
                                          opacity:
                                            sortBy === column.name &&
                                            sortOrder === "desc"
                                              ? 1
                                              : 0.5,
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {fetched &&
                        dataList.length > 0 &&
                        (searchKeyword.length > 0 ? searchResult : dataList)
                          .slice()
                          .sort(
                            sortByFun
                              ? sortOrder === "desc"
                                ? (a, b) => sortByFun(a, b) * -1
                                : (a, b) => sortByFun(a, b)
                              : () => 0
                          )
                          .slice(startIdx, endIdx + 1)
                          .map((data, idx) => {
                            return (
                              <tr
                                key={uuid()}
                                className={
                                  rowContainerClassFunction &&
                                  rowContainerClassFunction(data)
                                }
                              >
                                <td>{currentPage * numOfRows + idx + 1}</td>
                                {columnData.length &&
                                  columnData.map((column) => (
                                    <td key={uuid()}>
                                      {column.transform
                                        ? column.transform(data)
                                        : data[column.value]}
                                    </td>
                                  ))}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="container pt-2">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 d-flex justify-content-center">
                  <button
                    disabled={currentPage === 0}
                    onClick={handlePrevPage}
                    className="btn btn-primary positive-relative p-2 fa fa-chevron-left"
                  />

                  <div className="col-3 col-md-5 d-flex justify-content-around align-items-center direct">
                    {[
                      currentPage - 2,
                      currentPage - 1,
                      currentPage,
                      currentPage + 1,
                      currentPage + 2,
                    ].map((pageNum, idx) => {
                      return (
                        pageNum < totalPages &&
                        pageNum >= 0 && (
                          <span
                            key={uuid()}
                            onClick={() => handlePageChange(pageNum)}
                            className={`${
                              idx === 0 || idx === 5
                                ? "d-none d-md-inline-block"
                                : ""
                            } ${
                              idx === 2 ? "text-primary font-weight-bold" : ""
                            } p-3 user-select-none pointer`}
                          >
                            {pageNum + 1}
                          </span>
                        )
                      );
                    })}
                  </div>

                  <button
                    disabled={currentPage >= totalPages - 1}
                    onClick={handleNextPage}
                    className="btn btn-primary position-relative p-2 fa fa-chevron-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

DataTable.propTypes = {
  searchByList: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnData: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableName: PropTypes.string.isRequired,
};

// DataTable.defaultProps = {
//   searchByList: [],
//   columnData: [],
//   tableName: "",
// }

export default DataTable;
