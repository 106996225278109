import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { API_URL } from '../../../utils';
import Container from '../../common/Container';

const VendorCategoryDetails = () => {
    const {user_id}= useParams()
    const [vendorCategory, setVendorCategory] = useState(null);
    const [loading, setLoading] = useState(true);
 

    useEffect(() => {
        const fetchVendorCategory = async () => {
            try {
                const response = await axios.get(`${API_URL}shop_category/admin/${user_id}`,
                    {
                        headers: {
                            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBmNDdlNDU5NDliZmFkYTVjZDNkNTEiLCJpYXQiOjE3MTY3OTAxMjQsImV4cCI6MTc0ODMyNjEyNH0.otLzAFe7Ba8Qy8K7CNFTpajda7Yqwm23adJEvqu4vJs',
                        },
                    }
                );
                console.log(response);
                setVendorCategory(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching vendor category:', error);
                setLoading(false);
            }
        };

        fetchVendorCategory();
    }, []);

    if (loading) {
        return (
            <div className="container text-center mt-5">
                <div className="spinner-border" role="status">
                </div>
            </div>
        );
    }

    if (!vendorCategory) {
        return (
            <div className="container text-center mt-5">
                <p>No vendor category data available.</p>
            </div>
        );
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();

        // Format day suffix
        const daySuffix = ['st', 'nd', 'rd'][((day + 90) % 100 - 10) % 10 - 1] || 'th';

        // Format time in 12-hour format
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Hour '0' should be '12'
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${day}${daySuffix} ${month} ${year}, ${hours}:${formattedMinutes} ${ampm}`;
    };

    return (
        <Container>
            <div className="container">
                <div className="card" style={{ maxWidth: '800px', margin: '0 auto' }}>
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="card-title" style={{ fontSize: '1.875rem' }}>{vendorCategory.title}</h3>
                            <img
                                src={vendorCategory.image[0].location}
                                className="img-thumbnail"
                                alt={vendorCategory.image[0].alt}
                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                            />
                        </div>
                        <div className="mb-3">
                            <h5>Category Details</h5>
                            <hr />
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th scope="row"><h6>Slug</h6></th>
                                        <td>{vendorCategory.slug}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><h6>Status</h6></th>
                                        <td>{vendorCategory.status ? 'Active' : 'Inactive'}</td>
                                    </tr>
                                    </tbody>
                                    </table>
                            <div className="my-3">
                                <h5>Meta Information</h5>
                                <hr />
                                <table className="table table-bordered">
                                    <tbody>
                                            
                                       
                                   
                                    {vendorCategory.meta.map((meta) => (
                                        <tr key={meta._id}>
                                            <th scope="row"><h6>{meta.name}</h6></th>
                                            <td>{Array.isArray(meta.content) ? meta.content.join(', ') : meta.content}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                            <th scope="row"><h6>Created At</h6></th>
                                            <td>{formatDate(vendorCategory.createdAt)}</td>
                                    </tr>
                                    <tr>
                                            <th scope="row"><h6>Updated At</h6></th>
                                            <td>{formatDate(vendorCategory.updatedAt)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Container>
    );
};

export default VendorCategoryDetails;
