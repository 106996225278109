import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AsyncThunks } from "../../redux/store";
import UserService from "../../services/user.service";

const StatusSwitch = ({ data, ofWhat, statusAttrName = "status" }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(data[statusAttrName]);
  const handleStatusChange = async () => {
    setChecked(!checked);
    const res = await UserService.put[ofWhat](data["_id"], {
      [statusAttrName]: !data[statusAttrName],
    });
    if (res.status === 200)
      AsyncThunks[`fetch${ofWhat}List`] &&
        dispatch(AsyncThunks[`fetch${ofWhat}List`]());
  };
  const _checked = data[statusAttrName];
  useEffect(() => {
    setChecked(data[statusAttrName]);
  }, [_checked]);
  // console.log(data, ofWhat, statusAttrName, data[statusAttrName], checked);
  const uuid = Math.random(0, 1000).toString(2);
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={"customSwitch" + uuid}
        name="status"
        checked={checked}
        onChange={handleStatusChange}
      />
      <label className="custom-control-label" htmlFor={"customSwitch" + uuid} />
    </div>
  );
};

export default StatusSwitch;
