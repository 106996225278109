import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
// import { fetchUnitCategoryList } from "../../../../redux/unitCategorySlice";
import { fetchVendorCategoryList } from "../../../../../redux/vendorCatgorySlice";
// Validation schema
const newUnitCategorySchema = Yup.object().shape({
  category_name: Yup.string().required("Category name is required"),
});

export const ModalForm = ({ submitHandler, actionsLoading, onHide, preFill }) => {
  const dispatch = useDispatch();
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          preFill ? preFill.values : { category_name: "" }
        }
        validationSchema={newUnitCategorySchema}
        onSubmit={async (values) => {
          console.log(values);
          const res = await (preFill
            ? submitHandler(preFill.id, values)
            : submitHandler(values));
          onHide();
          if (res.status === 200) dispatch(fetchVendorCategoryList());
        }}
      >
        {({ handleSubmit, values, isValid, handleChange }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="justify-content-around row flex-column mb-0" style={{ gap: "10px" }}>
                  {/* Category Name */}
                  <div className="col form-group">
                    <label htmlFor="category_name">Category Name</label>
                    <input
                      className="form-control"
                      name="category_name"
                      placeholder="Category Name"
                      id="category_name"
                      value={values.category_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-around">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!isValid}
                className="btn btn-primary btn-elevate"
              >
                {preFill ? "Save" : "Create"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};