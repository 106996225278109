import React, { useState } from 'react';
import Container from '../../common/Container';
import { Form, Button, Row, Col, InputGroup, FormControl, Image, Card} from 'react-bootstrap';


const GeneralSettings = () => {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [privacy, setPrivacy] = useState('public');
  const [defaultVendorLogoUrl, setDefaultVendorLogoUrl] = useState('https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png');


  return (
    <Container>
      <div className="bg-light bg-gradient container p-4 rounded-lg shadow-sm">
        <h4 className="font-weight-bold mb-4 text-dark">General Settings</h4>

        <section className="mb-5">
          <h4 className="font-weight-semibold rounded-lg mb-4">Store Details</h4>
          <Row className='my-4'>
            <Col md={4}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Vendor Logo</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control type="file" className="mx-2 rounded-lg" />
                    {defaultVendorLogoUrl && (
                      <Image src={defaultVendorLogoUrl} alt="Current Logo" rounded style={{ maxWidth: '100px', height: 'auto' , marginLeft: '2%'}} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Product Icon</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control type="file" className="mx-2" />
                    {defaultVendorLogoUrl && (
                      <Image src="https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png" alt="Current Icon" rounded style={{ maxWidth: '100px', height: 'auto', marginLeft: '2%' }} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-sm p-3 mb-4 bg-white rounded-lg">
                <Form.Group controlId="storeLogo" className="">
                  <Form.Label className='ml-2'>Default Catalog Icon</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control type="file" className="mx-2" />
                    {defaultVendorLogoUrl && (
                      <Image src="https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png" alt="Current Icon" rounded style={{ maxWidth: '100px', height: 'auto', marginLeft: '2%' }} />
                    )}
                  </div>
                  
                </Form.Group>
              </Card>
            </Col>
          </Row>
          <h4>Details</h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="companyName" className='my-2'>
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" placeholder="Enter company name" />
              </Form.Group></Col>
            
            <Col md={4}>
              <Form.Group controlId="mobileNo" className='my-2'>
                <Form.Label>Mobile No</Form.Label>
                <Form.Control type="tel" placeholder="Enter mobile number" />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="privacy" className='my-2'>
                <Form.Label>Account Privacy</Form.Label>
                <Form.Control
                  as="select"
                  value={privacy}
                  onChange={(e) => setPrivacy(e.target.value)}
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="friends-only">Friends Only</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col md={4}>
              <Form.Group controlId="emailId" className='my-2'>
                <Form.Label>Email ID</Form.Label>
                <Form.Control type="email" placeholder="Enter email address" />
              </Form.Group></Col>
            
            <Col md={4}>
              <div className="my-4">
                <label className="my-4 mx-2 flex items-center">
                  <span className="text-gray-700 pr-4">Email Notifications</span>
                  <input
                    type="checkbox"
                    checked={emailNotifications}
                    onChange={() => setEmailNotifications(!emailNotifications)}
                    className="form-checkbox w-5 h-5 p-4"
                  />
                </label>
              </div>
            </Col>
            
          </Row>

          <h4>Social Media</h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>Facebook</Form.Label>
                <Form.Control type="url" placeholder="Enter Facebook url" />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>Instagram</Form.Label>
                <Form.Control type="url" placeholder="Enter Instagram url" />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="socialMediaLink" className='my-2'>
                <Form.Label>LinkedIn</Form.Label>
                <Form.Control type="url" placeholder="Enter LinkedIn url" />
              </Form.Group>
            </Col>
          </Row>


          <Button variant="dark" type="submit" className='my-4'>Save Changes</Button>
        </section>

        
          
            
          
          
      
      </div>
    </Container>
  );
};

export default GeneralSettings;
