import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchCatalogList = createAsyncThunk(
  "catalog/fetchList",
  async () => {
    try {
      const res = await UserService.get.CatalogList();
      return res.data && res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const { reducer: CatalogReducer } = createSlice({
  name: "Catalog",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchCatalogList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default CatalogReducer;
