import React from "react";
import { Link } from "react-router-dom";
// import Sidebar from "./Sidebar";
import AuthServices from "../../services/auth.service";

class Header extends React.Component {
  constructor() {
    super();
    this.logOut = this.logOut.bind(this);
  }
  async logOut() {
    await AuthServices.logout();
  }
  render() {
    return (
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row bg-dark shadow-sm">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo mr-5 position-relative" to="/dashboard">
            <img
              src="https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png"
              className="mr-2"
              alt="logo"
              style={{ maxHeight: '40px' }}
            />
            <span className="font-weight-bold text-white">Lofaz</span>
          </Link>
          <Link className="pl-4 navbar-brand brand-logo-mini" to="/dashboard" style={{ zIndex: 10 }}>
            <img
              src="https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png"
              alt="logo"
              style={{ maxHeight: '30px' }}
            />
            <span className="font-weight-bold text-white">Lofaz</span>
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
           {/* <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu"></span>
          </button> */}

          <ul className="navbar-nav mr-lg-2">
            {/* <li className="nav-item nav-search d-none d-lg-block">
              <div className="input-group">
                <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
                  <span className="input-group-text" id="search">
                    <i className="icon-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="navbar-search-input"
                  placeholder="Search now"
                  aria-label="search"
                  aria-describedby="search"
                />
              </div>
            </li> */}
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown flex items-center">
              <a className="dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                <img
                  src="https://thumbs.dreamstime.com/b/portrait-young-man-beard-hair-style-male-avatar-vector-portrait-young-man-beard-hair-style-male-avatar-105082137.jpg"
                  alt="profile"
                  className="rounded-circle"
                  style={{ width: '40px', height: '40px', marginBottom: '15px' }}
                />
              </a>
              <div className="bg-dark dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                <a className="dropdown-item" href="/basic">
                  <i className="ti-settings text-light"></i>
                  <span className="custom-hover" style={{color: 'white'}}>Settings</span>
                </a>
                <a className="dropdown-item" href="/" onClick={this.logOut}>
                  <i className="ti-power-off text-light"></i>
                  <span className="custom-hover" style={{color: 'white'}}>Logout</span>
                </a>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu"></span>
          </button>
        </div>
      </nav>

    );
  }
}

export default Header;
