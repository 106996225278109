import React, { Component } from "react";
import { API_URL, APP_URL } from "../../../utils";
import UserService from "./../../../services/user.service";
import Container from "./../../common/Container";
import StatusSwitch from "./../../common/StatusSwitch";
import DataTable from "react-data-table-component";
import Catalog, { getCatalogTableConfig } from "../Catalog/Catalog";
import { getProductTableConfig } from "../Product";
import { getOrderTableConfig } from "../Order";
import { useEffect } from "react";
import axios from "axios";
import Product from "../Product";
import Order from "../Order";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { getCustomerTableConfig } from "../Customer/Customer";
import Customer from "../Customer/Customer";

class VendorDetails extends Component {
  state = {
    vendor: {},
    catalogs: [],
    products: [],
    customers: [],
    orders: [],
    settings: {},
    fetched: false,
    vendorNotFound: false,
  };

  async componentDidMount() {
    const {
      match: {
        params: { user_id },
      },
    } = this.props;

    // if (!/^\d+$/.test(user_id)) {
    //   // If user_id is not an integer, redirect to a "not found" page or some other error handling
    //   return <Redirect to="/Not-found" />;
    // }

    try {
      const response = await axios.get(`${API_URL}/shop/admin/v1`, {
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0',
        },
      });
      const data = response.data.vendor_data;
      console.log("data", data);
      // Filter the data to find the vendor with the specific ID
      const vendor = data.find((vendor) => vendor._id === user_id);

      if (!vendor) {
        this.setState({ vendorNotFound: true, fetched: true });
        return;
      }

      console.log("ven", vendor);
      this.setState({ vendor, fetched: true });

      // Fetch catalog data with history
      const catalogData = await getCatalogTableConfig(this.props.history);
      if (Array.isArray(catalogData.data)) {
        const catalogs = catalogData.data.filter((catalog) => catalog.user_id === user_id);
        if (catalogs) {
          this.setState({ catalogs });
        }
      }

      // Fetch product data
      const productData = await getProductTableConfig(this.props.history);
      if (Array.isArray(productData.data)) {
        const products = productData.data.filter((product) => product.user_id === user_id);
        if (products) {
          this.setState({ products });
        }
      }
      const custData = await getCustomerTableConfig(this.props.history);
      
      if (Array.isArray(custData.data)) {
        const customers = custData.data.filter((cust) => cust._id === user_id);
        console.log("cust", customers);
        if (customers) {
          this.setState({ customers });
        }
      }

      // Fetch order data
      const orderData = await getOrderTableConfig(this.props.history);
      if (Array.isArray(orderData)) {
        const orders = orderData.filter((order) => order._id === user_id);
        console.log("ooooo",orders);
        if (orders) {
          this.setState({ orders });
        }
      } else {
        console.error('Expected an array but got:', orderData);
        this.setState({ catalogs: [], products: [], customers:[], orders: [] });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  render() {
    const { vendor, catalogs, products, customers, orders, settings, fetched, vendorNotFound } = this.state;
    const { user_id } = this.props.match.params;
    const { history } = this.props;
    console.log("ooo",orders);

    const catalogInstance = new Catalog();
    const catalogColumns = catalogInstance.columns;
    //.log(catalogColumns);

    const productInstance = new Product();
    const productColumns = productInstance.columns;
    //console.log(productColumns);

    const orderInstance = new Order();
    const orderColumns = orderInstance.columns;
    console.log("o", orderColumns);

    const custInstance = new Customer();
    const custColumns = custInstance.columns;
    console.log("c", custColumns);


    if (vendorNotFound) {
      return (
        <Container>
          <div className="pt-4 px-4">
            <h4 className="text-center">No vendor found with the given ID: {user_id}</h4>
          </div>
        </Container>
      );
    }

    if (!fetched) {
      return <div>Loading...</div>;
    }

    return (
      <Container>
        <div className="pt-4 px-4">
          <h4 className="text-center">User Id: {user_id}</h4>
        </div>

        <div className="card container p-4 mt-3">
          <nav>
            <div className="nav nav-tabs" style={{ width: "100%" }} id="nav-tab" role="tablist">
              <a
                className="nav-link active nav-link-flex"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="true"
                style={{ color: 'black' }}
              >
                <p style={{ color: 'black' }}>Profile</p>
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-catalog-tab"
                data-toggle="tab"
                href="#nav-catalog"
                role="tab"
                aria-controls="nav-catalog"
                aria-selected="true"
              >
                Catalogs ({catalogs.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-product-tab"
                data-toggle="tab"
                href="#nav-product"
                role="tab"
                aria-controls="nav-product"
                aria-selected="false"
              >
                Products ({products.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-cust-tab"
                data-toggle="tab"
                href="#nav-cust"
                role="tab"
                aria-controls="nav-cust"
                aria-selected="false"
              >
                Customers ({customers.length})
              </a>
              <a
                className="nav-link nav-link-flex"
                id="nav-order-tab"
                data-toggle="tab"
                href="#nav-order"
                role="tab"
                aria-controls="nav-order"
                aria-selected="false"
              >
                Orders ({orders.length})
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
                  <h5 className="mb-0">Vendor Profile</h5>
                  <img
                    className="rounded-circle"
                    src={vendor.photo ? vendor.photo : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"}
                    alt={vendor.username}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div className="card-body row">
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" , color:"black" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Contact Information</h6>
                    <div className="mt-4">
                      <p className="m-2"><strong>Name:</strong> {vendor.full_name || ""}</p>
                      <p className="m-2"><strong>Phone:</strong> <a href={`tel:${vendor.phone}`}>{vendor.phone || ""}</a></p>
                      <p className="m-2"><strong>Email:</strong> {vendor.email || ""}</p>
                      <p className="m-2"><strong>Username:</strong> <a href={APP_URL + vendor["username"]} target="_blank" rel="noopener noreferrer">{vendor["username"] || ""}</a></p>
                      <p className="m-2"><strong>Business Name:</strong> {vendor.business_name || ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3" style={{ borderRight: "1px solid gray" }}>
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Address</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 1:</strong> {vendor.address_line1 || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Address Line 2:</strong> {vendor.address_line2 || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>City:</strong> {vendor.city || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Zip:</strong> {vendor.zip || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>State:</strong> {vendor.state || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Country:</strong> {vendor.country || ""}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <h6 className="text-uppercase text-center" style={{ color: 'black' }}>Additional Information</h6>
                    <div className="mt-4">
                      <p className="m-2" style={{ color: 'black' }}><strong>Created At:</strong> {new Date(vendor.createdAt).toLocaleString() || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Last Login:</strong> {new Date(vendor.updatedAt).toLocaleString() || ""}</p>
                      <p className="m-2" style={{ color: 'black' }}><strong>Account Status:</strong> {vendor.activate ? "Active" : "Inactive"}</p>
                      <div className="m-2" style={{ color: 'black' }}>
                        <p><strong>Shop Status:</strong>
                          <StatusSwitch
                            data={{ _id: user_id, shop_status: vendor.activate ? "true" : "false" }}
                            statusAttrName="shop_status"
                            ofWhat="VendorSettings"
                          />
                        </p>
                      </div>
                      <div className="m-2">
                        <p style={{ color: 'black' }}><strong>Order Status:</strong>
                          <StatusSwitch
                            data={{ _id: user_id, order_status: settings.order_status }}
                            statusAttrName="order_status"
                            ofWhat="VendorSettings"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-catalog"
              role="tabpanel"
              aria-labelledby="nav-catalog-tab"
            >
              <DataTable
                {...getCatalogTableConfig(history)}
                data={catalogs}
                columns={catalogColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={catalogs.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-product"
              role="tabpanel"
              aria-labelledby="nav-product-tab"
            >
              <DataTable
                {...getProductTableConfig(history)}
                data={products}
                columns={productColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={products.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-cust"
              role="tabpanel"
              aria-labelledby="nav-cust-tab"
            >
              <DataTable
                {...getCustomerTableConfig(history)}
                data={customers}
                columns={custColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={customers.length} // Total rows count
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-order"
              role="tabpanel"
              aria-labelledby="nav-order-tab"
            >
              <DataTable
                {...getOrderTableConfig(history)}
                data={orders}
                columns={orderColumns}
                fetched={fetched}
                pagination
                paginationServer
                paginationTotalRows={orders.length} // Total rows count
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default VendorDetails;
