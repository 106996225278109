import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchBannerList = createAsyncThunk(
  "banner/fetchList",
  async () => {
    try {
      const res = await UserService.getBannerList();
      return res.data && res.data.data;
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error further
    }
  }
);

const { reducer: bannerReducer } = createSlice({
  name: "banner",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchBannerList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
    },
  },
});

export default bannerReducer;
