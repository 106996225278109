import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../../../../services/user.service";
import { ModalForm } from "./ModalForm";

export function NewSuggestedVendorModal({ show, onHide, preFill }) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-sm" className="model-title">
          {preFill ? "Edit" : "Add New"} Vendor
        </Modal.Title>
        <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      
      <ModalForm
        onHide={onHide}
        preFill={preFill}
        submitHandler={UserService[preFill ? "put" : "post"].Vendor}
      />
    </Modal>
  );
}