import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchOrderList = createAsyncThunk("order/fetchList", async () => {
  try {
    const res = await UserService.get.OrderList();
    return res.data && res.data;
  } catch (error) {
    console.log(error);
  }
});

const { reducer: OrderReducer } = createSlice({
  name: "Order",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchOrderList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default OrderReducer;
