import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchNotificationList } from "../../../../redux/notificationSlice";
import { photoSchema } from "../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newNotificationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  repeat: Yup.boolean().required(),
});

/*"title": "Lofaz",
"description": "Bhai Kiase Ho :)",
"photo": "abcde",
"repeat": true,
"time":"13:36",
"start": "06/07/2021",
"end" */

export const ModalForm = ({ submitHandler, onHide, preFill }) => {
  const dispatch = useDispatch();

  const [photo, setPhoto] = React.useState("");

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        (preFill && preFill.values) || {
          title: "",
          description: "",
          photo: "",
          repeat: true,
          time: "",
          start: "",
          end: "",
          imgType: "file",
        }
      }
      // validationSchema={
      //   preFill
      //     ? newNotificationSchema
      //     : newNotificationSchema.concat(photoSchema)
      // }
      onSubmit={async (values) => {
        let partsOfDate = values.start.split("-");
        values.start = `${partsOfDate[2]}/${partsOfDate[1]}/${partsOfDate[0]}`;
        partsOfDate = values.end.split("-");
        values.end = values.repeat
          ? `${partsOfDate[2]}/${partsOfDate[1]}/${partsOfDate[0]}`
          : "";
        const res = await (preFill
          ? submitHandler(preFill.id, values)
          : submitHandler(values));
        if (res.status === 200) {
          onHide();
          dispatch(fetchNotificationList());
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue, isValid }) => (
        <>
          {(() => console.log(values))()}
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3 d-flex flex-column justify-content-center">
                  <label htmlFor="title">Title</label>
                  <Field
                    className="form-control"
                    name="title"
                    placeholder="Title"
                    id="title"
                  />
                </div>
                <div className="col-lg-3 d-flex flex-column justify-content-center">
                  <label htmlFor="description">Description</label>
                  <Field
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Description"
                  />
                </div>
                <div className="col-lg-3 d-flex flex-column justify-content-center">
                  <div role="group" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="imgType" value="file" />
                      File
                    </label>
                    <label className="ml-2">
                      <Field type="radio" name="imgType" value="url" />
                      URL
                    </label>
                  </div>

                  <div>
                    {values.imgType === "file" ? (
                      <input
                        type="file"
                        className="form-control-file"
                        name="photo"
                        id="photo"
                        onChange={(event) => {
                          setPhoto(
                            URL.createObjectURL(event.currentTarget.files[0])
                          );
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }}
                      />
                    ) : (
                      <Field
                        className="form-control"
                        id="photo"
                        name="photo"
                        placeholder="Image URL"
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-3 d-flex flex-column justify-content-center">
                  {(values.image || values.photo || photo) && (
                    <img
                      src={values.image || values.photo || photo}
                      alt=""
                      width="150"
                      className="m-3"
                    />
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="time">Time</label>
                  <Field
                    id="time"
                    name="time"
                    type="time"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-2">
                  <label htmlFor="repeat">Repeat</label>
                  <div className="custom-control custom-switch">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="repeat"
                      name="repeat"
                      checked={values.repeat}
                    />
                    <label className="custom-control-label" htmlFor="repeat" />
                  </div>
                </div>
                <div className="col-lg-3">
                  <label htmlFor="start">
                    {values.repeat ? "Start" : "Date"}
                  </label>
                  <Field
                    id="start"
                    name="start"
                    type="date"
                    className="form-control"
                  />
                </div>
                {values.repeat && (
                  <div className="col-lg-3 d-flex flex-column justify-content-center">
                    <label htmlFor="end">End</label>
                    <Field
                      id="end"
                      name="end"
                      type="date"
                      className="form-control"
                    />
                  </div>
                )}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
              disabled={!isValid}
            >
              {preFill ? "Save" : "Create"}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
