import React, { Component } from "react";
import { getOrderTableConfig } from "../Order";
import UserService from "./../../../services/user.service";
import Container from "./../../common/Container";
import DataTable from "react-data-table-component";
import { getCustomerTableConfig } from "./Customer";

class CustomerDetails extends Component {
  state = {
    customer: [],
    orders: [],
    fetched: false,
  };
  async componentDidMount() {
    const {
      match: {
        params: { cust_id },
      },
    } = this.props;

    console.log("cust",cust_id);
    const custData = await getCustomerTableConfig(this.props.history);
    if (Array.isArray(custData.data)) {
      const customer = custData.data.filter((cust) => cust._id === cust_id);
      console.log("cust", customer);
      if (customer) {
        this.setState({ customer });
      }
    }

  }
  render() {
    const {
      state: { customer, orders, fetched },
    } = this;
    console.log("cu",customer);
    return (
      <Container>
        <div className="card container p-4">
          <div className="row justify-content-between">
            <h5 className="card-title col-6">Customer Details</h5>
            <div className="col-2">
              <img
                className="rounded-circle"
                src={
                  customer.photo
                    ? customer.photo
                    : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"
                }
                alt={customer.full_name}
                height="80"
              />
            </div>
          </div>
          <div className="card-body row customer-details flex-column">
            <div className="col row mb-3">
              <div className="col">
                <span>Name</span>
                <p>{customer.full_name || "-"}</p>
              </div>
              <div className="col">
                <span>Phone</span>
                <a href={`tel:${customer.phone}`} className="d-block">
                  {customer.phone || "-"}
                </a>
              </div>
            </div>
            <div className="col row  mb-3">
              <div className="col">
                <span>Address Line 1</span>
                <p>{customer.address_line1 || "-"}</p>
              </div>
              <div className="col">
                <span>Address Line 2</span>
                <p>{customer.address_line2 || "-"}</p>
              </div>
              <div className="col">
                <span>City</span>
                <p>{customer.city || "-"}</p>
              </div>
              <div className="col">
                <span>Zip</span>
                <p>{customer.zip || "-"}</p>
              </div>
              <div className="col">
                <span>State</span>
                <p>{customer.state || "-"}</p>
              </div>
              <div className="col">
                <span>Country</span>
                <p>{customer.country || "-"}</p>
              </div>
            </div>
            <div className="col row  mb-3">
              <div className="col">
                <span>Created At</span>
                <p>{customer.createdAt || "-"}</p>
              </div>
              <div className="col">
                <span>Last login</span>
                <p>{customer.last_login || "-"}</p>
              </div>
              <div className="col">
                <span>Updated At</span>
                <p>{customer.updatedAt || "-"}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card container p-4 mt-3">
          <DataTable
            {...getOrderTableConfig()}
            fetched={fetched}
            dataList={orders}
          />
        </div>
      </Container>
    );
  }
}

export default CustomerDetails;
