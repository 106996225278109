import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./component/page/Login";
import { ErrorPage } from "./component/page/ErrorPage/ErrorPage";
import BasePage from "./BasePage";
import authService from "./services/auth.service";

class App extends React.Component {
  render() {
    const isAuthorized = authService.isAuthed();
    return (
      <BrowserRouter>
        <Switch>
          {!isAuthorized ? (
            /*Render auth page when user at `/auth` and not authorized.*/
            <Route>
              <Login />
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <Redirect from="/auth" to="/" />
          )}

          {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to="/auth" />
          ) : (
            <BasePage />
          )}
          <Route path="/" component={ErrorPage} />
        </Switch>
      </BrowserRouter>

    );
  }
}

export default App;
//checked
