import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchNotificationList } from "../../../../redux/notificationSlice";
import { photoSchema } from "../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newNotificationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  repeat: Yup.boolean().required(),
});

/*"title": "Lofaz",
"description": "Bhai Kiase Ho :)",
"photo": "abcde",
"repeat": true,
"time":"13:36",
"start": "06/07/2021",
"end" */

export const ModalForm = ({ submitHandler, onHide, preFill }) => {
  const dispatch = useDispatch();

  const [photo, setPhoto] = React.useState("");

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        (preFill && preFill.values) || {
          title: "",
        }
      }
    // validationSchema={
    //   preFill
    //     ? newNotificationSchema
    //     : newNotificationSchema.concat(photoSchema)
    // }
    >
      {({ values, handleSubmit, setFieldValue, isValid }) => (
        <>
          {(() => console.log(values))()}
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form className="form form-label-right">
            <div className="form-group">
              <label htmlFor="Country">Country</label>
                <select
                  className="form-control"
                  name="country"
                  id="country"
                  value="country"
                  onChange=""
                >
                  <option value="fruit">India</option>
                  <option value="vegetable">Bangladesh</option>
                  <option value="meat">USA</option>
                </select>
              </div>
              <div className="form-group">
              <label htmlFor="State">State</label>
                <select
                  className="form-control"
                  name="state"
                  id="state"
                  value="state"
                  onChange=""
                >
                  <option value="fruit">Maharashtra</option>
                  <option value="vegetable">Punjab</option>
                  <option value="meat">Gujarat</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="country">City</label>
                <Field
                  className="form-control"
                  name="country"
                  placeholder="Add city"
                  id="country"
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Edit
            </button>
            <> </>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
              disabled={!isValid}
            >
              Save
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
