import React from "react";
import { useHistory } from "react-router";
import { handleDelete, wrapDataTable } from "../../../utils";
import AddButton from "../../common/AddButton";
import Container from "../../common/Container";
import StatusSwitch from "../../common/StatusSwitch";

const ViewCategoryButton = ({ handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <button className="btn btn-primary p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

const CategoryDataTable = wrapDataTable("Category");

const Category = () => {
  const history = useHistory();
  return (
    <Container>
      <CategoryDataTable
        utilButtons={[
          <AddButton
            label="New Category"
            handler={() => history.push("category/new")}
          />,
        ]}
        tableName="Categories"
        searchByList={[
          { label: "Name", value: "category_title" },
          {
            label: "Status",
            value: (data) => (data["status"] ? "Active" : "Inactive"),
          },
        ]}
        columnData={[
          { name: "Name", value: "category_title" },
          { name: "Type", value: "type" },
          {
            name: "Status",
            transform: (data) => <StatusSwitch data={data} ofWhat="Category" />,
          },
          {
            name: "Action",
            transform: (data) => (
              <ViewCategoryButton
                handleEdit={() =>
                  history.push({
                    pathname: "/category/new",
                    state: {
                      id: data["_id"],
                      values: {
                        title: data["category_title"],
                        type: data["type"],
                      },
                    },
                  })
                }
                handleDelete={() =>
                  handleDelete(data && data["_id"], "Category")
                }
              />
            ),
          },
        ]}
        defaultSortBy="Created on"
        defaultSortByFun={(a, b) =>
          new Date(a["createdAt"]) - new Date(b["createdAt"])
        }
      />
    </Container>
  );
};

export default Category;
