import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchVendorCategoryList } from "../../../../redux/vendorCatgorySlice";
import axios from "axios";
import { API_URL } from "../../../../utils";
import authHeader from "../../../../services/auth-header";
// Validation schema
export const photoSchema = Yup.object().shape({
  images: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed().required("Image is required"),
        alt: Yup.string().optional(),
        status: Yup.boolean().optional(),
      })
    )
    .min(1, "At least one image is required")
    .max(2, "A maximum of two images is allowed"),
});

const newVendorCategorySchema = Yup.object().shape({
  title: Yup.string().required("Name is required"),
  slug: Yup.string().required("Slug is required"),
  status: Yup.string().optional(),
  meta: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Meta Name is required"),
      content: Yup.string().required("Meta Content is required"),
      // keyword: Yup.string().required("Meta Keyword is required"),
    })
  ),
});

export const ModalForm = ({
  actionsLoading,
  onHide,
  preFill,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="mb-4">
      <Formik
        enableReinitialize={true}
        initialValues={
          preFill && preFill.values ? preFill.values : {
            images: [{ file: null, alt: "", status: false }],
            title: "",
            slug: "",
            status: false,
            meta: [{ name: "title", content: "This is some sample title for " },
              { name: "description", content: "This is some sample description for " },
              { name: "keywords", content: [] }],
          }
        }
        validationSchema={
          preFill
            ? newVendorCategorySchema
            : newVendorCategorySchema.concat(photoSchema)
        }
        onSubmit={async (values, { resetForm }) => {
          try {
            const authHeaderObject = new authHeader();
            // Create FormData object
            const formData = new FormData();
            formData.append("title", values.title);

            let slugg=values.slug.toLowerCase().replace(/\s+/g, '-');
            formData.append("slug", slugg);
            formData.append("status", values.status);

            values.images.forEach((image, index) => {
              if (image.file) {
                formData.append(`image`, image.file);
                formData.append(`image[${index}][alt]`, image.alt);
                formData.append(`image[${index}][status]`, image.status);
              }
            });


            values.meta.forEach((meta, index) => {
              formData.append(`meta[${index}][name]`, meta.name);
              if (meta.name === "keywords") {
                const keywordsArray = meta.content.split(',').map(keyword => keyword.trim());
                keywordsArray.forEach((keyword, keywordIndex) => {
                  formData.append(`meta[${index}][content][${keywordIndex}]`, keyword);
                });
              } else {
                formData.append(`meta[${index}][content]`, meta.content);
              }
            });
            // values.meta.forEach((meta, index) => {
            //   formData.append(`meta[${index}][name]`, meta.name);
            //   formData.append(`meta[${index}][content]`, meta.content);
            //   const keywordsArray = meta.keyword.split(',').map(keyword => keyword.trim());
            //   keywordsArray.forEach((keyword, keywordIndex) => {
            //     formData.append(`meta[${index}][keyword][${keywordIndex}]`, keyword);
            //   });
            // });

            console.log("formData", formData);

            // Submit FormData using axios
            const response = await axios.post(`${API_URL}shop_category/admin`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeaderObject,
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
              },
              mode: "cors",
              cache: 'default'
            });

            console.log("resp", response);


            if (response.status === 200) {
              dispatch(fetchVendorCategoryList());
              onHide();
              resetForm(); // Reset the form after successful submission
            }

          } catch (error) {
            console.error("Error submitting form:", error);
          }
        }}
      >
        {({ handleSubmit, values, isValid, handleChange, setFieldValue }) => {
          console.log("Formik values", values);
          return (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div
                    className="justify-content-around row flex-column mb-0"
                    style={{ gap: "10px" }}
                  >
                    <FieldArray name="images">
                      {({ push, remove }) => (
                        <>
                          {values.images.map((image, index) => (
                            <div key={index} className="col d-flex flex-column">
                              {image.file && (
                                <img src={URL.createObjectURL(image.file)} alt="" width="100" />
                              )}
                              <div className="form-group">
                                <label htmlFor={`images[${index}].file`}>Image</label>
                                <input
                                  className="form-control-file"
                                  type="file"
                                  name={`images[${index}].file`}
                                  id={`images[${index}].file`}
                                  onChange={(event) => {
                                    setFieldValue(
                                      `images[${index}].file`,
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`images[${index}].alt`}>Image Alt</label>
                                <input
                                  className="form-control"
                                  name={`images[${index}].alt`}
                                  placeholder="Alt"
                                  id={`images[${index}].alt`}
                                  value={image.alt}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
                                <label htmlFor={`images[${index}].status`}>Image Status</label>
                                <input
                                  className="form-control"
                                  name={`images[${index}].status`}
                                  type="checkbox"
                                  id={`images[${index}].status`}
                                  checked={image.status}
                                  onChange={handleChange}
                                  style={{ height: '24px' }}
                                />
                              </div>
                              {values.images.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="btn btn-danger mb-2"
                                >
                                  Remove Image
                                </button>
                              )}
                              {index === values.images.length - 1 && values.images.length < 2 && (
                                <button
                                  type="button"
                                  onClick={() => push({ file: null, alt: "", status: false })}
                                  className="btn btn-secondary text-white mb-2"
                                >
                                  Add Image
                                </button>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                    <div className="col form-group">
                      <label htmlFor="title">Name</label>
                      <input
                        className="form-control"
                        name="title"
                        placeholder="Name"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col form-group">
                      <label htmlFor="slug">Slug</label>
                      <input
                        className="form-control"
                        name="slug"
                        placeholder="Slug"
                        id="slug"
                        value={values.slug}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col form-group d-flex align-items-center justify-content-center" style={{ gap: "5px" }}>
                      <label htmlFor={"status"}>Vendor Status</label>
                      <input
                        className="form-control"
                        name="status"
                        type="checkbox"
                        id="status"
                        checked={values.status}
                        onChange={handleChange}
                        style={{ height: '24px' }}
                      />
                    </div>
                    <FieldArray name="meta">
                      {({ push, remove }) => (
                        <>
                          <h6 className="mx-auto">Meta Information</h6>
                          {values.meta.map((meta, index) => (
                            <div key={index} className="col form-group">
                              <input
                                className="form-control my-2"
                                name={`meta.${index}.name`}
                                placeholder="Meta Name"
                                id={`meta.${index}.name`}
                                value={meta.name}
                                onChange={handleChange}
                                disabled={["title", "description", "keywords"].includes(meta.name)}
                              />
                              <input
                                className="form-control my-2"
                                name={`meta.${index}.content`}
                                placeholder="Meta Content"
                                id={`meta.${index}.content`}
                                value={meta.content}
                                onChange={handleChange}
                              />
                              <div className="flex justify-content-between align-items-center m-4">
                                {["title", "description", "keywords"].includes(meta.name) ? null : (
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="btn btn-danger mr-2"
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className="col form-group">
                            <button
                              type="button"
                              onClick={() => push({ name: "", content: "" })}
                              className="btn btn-secondary text-white"
                            >
                              Add Meta Field
                            </button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className="justify-content-around">
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isValid}
                  className="btn btn-dark btn-elevate"
                >
                  {preFill ? "Save" : "Create"}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </div>
  );
};