import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchCountryList = createAsyncThunk(
  "country/fetchList",
  async () => {
    try {
      const res = await UserService.get.CountryList();
      return res.data && res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const { reducer: CountryReducer } = createSlice({
  name: "country",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchCountryList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default CountryReducer;
