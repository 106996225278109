import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchNotificationList = createAsyncThunk(
  "notification/fetchList",
  async () => {
    try {
      const res = await UserService.get.NotificationList();
      return res.data && res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const { reducer: NotificationReducer } = createSlice({
  name: "notification",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchNotificationList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default NotificationReducer;
