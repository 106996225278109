import axios from "axios";
import { API_URL } from "./../utils";

//Login Function
class AuthServices {
  login = async (username, password) => {
    try {
      return await axios.post(API_URL + "admin/signin", {
        email: username,
        password: password,
      });
    } catch (error) {
      return error;
    }
  };
  isAuthed() {
    return localStorage.getItem("user") !== null;
  }
  logout = async () => {
    try {
      localStorage.removeItem("user");
    } catch (error) {
      return error;
    }
  };
}
export default new AuthServices();
