import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../../../services/user.service";
import { ModalForm } from "./ModalForm";

export function NewSubCategoryModal({ show, onHide, preFill }) {
  const [actionLoading, setActionLoading] = React.useState(true);
  const [catList, setCatList] = React.useState([]);
  React.useEffect(() => {
    UserService.get
      .SuggestedCategoryList()
      .then((res) => res.data)
      .then(({ data: catList }) => {
        setCatList(catList);
        setActionLoading(false);
      });
  }, []);
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {preFill ? "Edit" : "Add New"} Sub Category
        </Modal.Title>
      </Modal.Header>
      {actionLoading ? (
        <div className="overlay-layer bg-transparent">
          <div className="spinner spinner-lg spinner-success" />
        </div>
      ) : (
        <ModalForm
          onHide={onHide}
          preFill={preFill}
          catList={catList}
          submitHandler={UserService[preFill ? "put" : "post"].SubCategory}
        />
      )}
    </Modal>
  );
}
