import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchPostList } from "../../../../redux/postSlice";
import { photoSchema } from "../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newPostSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  status: Yup.boolean().required(),
});

export const ModalForm = withRouter(
  ({ submitHandler, onHide, preFill, history, catList }) => {
    const dispatch = useDispatch();
    const [categoryId, setCategoryid] = React.useState(
      preFill ? preFill.category_id : catList[0] && catList[0]._id
    );
    const [catLabel, setCatLabel] = React.useState(
      preFill
        ? catList.length > 0 &&
            catList.find(({ _id }) => _id === preFill.category_id)
        : catList[0] && catList[0].category_title
    );
    const [photo, setPhoto] = React.useState("");
    const handleCategoryChange = ({ target: { value } }) => {
      setCatLabel(value);
      setCategoryid(
        catList.find(({ category_title }) => category_title === value)._id
      );
    };
    return (
      <Formik
        enableReinitialize={true}
        initialValues={
          (preFill && preFill.values) || {
            title: "",
            description: "",
            photo: "",
            status: true,
          }
        }
        validationSchema={
          preFill ? newPostSchema : newPostSchema.concat(photoSchema)
        }
        onSubmit={async (values) => {
          values = { ...values, category_id: categoryId };
          const res = await (preFill
            ? submitHandler(preFill.id, values)
            : submitHandler(values));
          if (res.status === 200) {
            onHide();
            dispatch(fetchPostList());
          }
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, isValid }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Title */}
                  <div className="col-lg-4">
                    <label htmlFor="title">Title</label>
                    <input
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      id="title"
                      onChange={handleChange}
                      value={values.title}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="storyCategory">Post Category</label>
                    <select
                      id="storyCategory"
                      className="text-capitalize form-control"
                      onChange={handleCategoryChange}
                      value={catLabel}
                    >
                      {catList.length > 0 &&
                        catList.map((cat) => (
                          <option
                            value={cat.category_title}
                            className="text-capitalize"
                          >
                            {cat.category_title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label htmlFor="description">Description</label>
                    <input
                      className="form-control"
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={handleChange}
                      value={values.description}
                    />
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div>
                      <label htmlFor="photo">Photo</label>
                      <input
                        type="file"
                        className="form-control-file"
                        name="photo"
                        id="photo"
                        onChange={(event) => {
                          setPhoto(
                            URL.createObjectURL(event.currentTarget.files[0])
                          );
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }}
                      />
                    </div>
                    {(photo || values.photo) && (
                      <img
                        src={photo || values.photo}
                        alt=""
                        width="150"
                        className="m-3"
                      />
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label for="customSwitch1">Status</label>
                    <div className="custom-control custom-switch">
                      <Field
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        name="status"
                        checked={values.status}
                        onChange={handleChange}
                      />
                      <label
                        className="custom-control-label"
                        for="customSwitch1"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
                disabled={!isValid}
              >
                {preFill ? "Save" : "Create"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    );
  }
);
