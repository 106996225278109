import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../utils";
import Container from "../common/Container";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { API_URL } from "../../utils";
import _ from 'lodash';
import AddButton from '../common/AddButton';
import { FaWhatsapp } from 'react-icons/fa'; 
import { saveAs } from "file-saver";
import XLSX from "xlsx";

const generateWhatsAppUrl = (phone) => {
  const message = encodeURIComponent(
    "Thank you for your order! Your order is currently pending and will be processed shortly. We appreciate your patience and will notify you with updates soon."
  );
  return `https://wa.me/${phone}?text=${message}`;
};

const ViewOrderButton = ({ handleDelete }) => (
  <div className="container">
    <div className="d-flex justify-center" >
      <button className="btn btn-danger p-2" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);



export const getOrderTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}order/admin/v1`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], totalCount: 0 };
  }
};

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      filterStatus: null, // State to manage showing orders by status
      isExpanded:false
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getOrderTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetched", data);
      this.setState({
        orderData: this.state.filterStatus ? data.filter(item => item.status[0]?.type === this.state.filterStatus) : data,
        totalRows: data.totalCount,
        fetched: true
      });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };



  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const limit = parseInt(searchParams.get('limit')) || 10;
    const search = searchParams.get('search') || '';
    const sort = searchParams.get('sort') || 'createdAt';
    const sortBy = parseInt(searchParams.get('sortBy')) || -1;
    return { currentPage, limit, search, sort, sortBy };
  }

  setFilterStatus = (status) => {
    this.setState({
      filterStatus: status,
      currentPage: 1
    }, () => {
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };

  togglePendingFilter = () => {
    this.setState(prevState => ({
      showPending: !prevState.showPending,
      currentPage: 1
    }), () => {
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  handleSearchChange = (e) => {
    let search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
    this.updateURLParams();
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
    this.updateURLParams();
    console.log("hi", this.state.currentPage, this.state.limit, this.state.search, this.state.sort, this.state.sortBy);
  }

    
downloadAsXls = () => {
  const { data } = this.state;
  const columns = this.columns.map(col => ({ name: col.name, selector: col.selector }));
  const tableName = 'VendorData'; // Customize this as needed
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: tableName,
    Subject: "Exported Data",
    Author: "Your Company",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Sheet1");
  // Prepare header
  const header = columns.map(col => col.name);
  const ws_data = [header];
  // Prepare data rows
  data.forEach(row => {
    const rowData = columns.map(col => row[col.selector] || ''); // Adjust based on your data structure
    ws_data.push(rowData);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Sheet1"] = ws;
  // Convert to binary string and save
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${tableName}_${new Date().toISOString().slice(0, 10)}.xlsx`
  );
};

  customSort = (rowA, rowB) => {
    const typeA = rowA.status[0]?.type === "pending" ? 1 : 0;
    const typeB = rowB.status[0]?.type === "pending" ? 1 : 0;
    return typeB - typeA; // This will prioritize "pending" types
  };


  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      //console.log(limit);
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
    this.updateURLParams();
  };

  columns = [
    { name: "SL", cell: (row, index) => <p>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },
    
    {
      name: "Order ID",
      cell: (row) => <p>{row["order_items"] ? row["order_items"][0]["order_id"] : ''}</p>,
      width: '250px'
    },
    {
      name: "Order Created at",
      cell: (data) => {
        const date = new Date(data.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'createdAt',
      sortable: true,
    },

    {
      name: "Product Name",
      cell: (row) => {
        //console.log(data);
        return (<p>{row.order_items && row.order_items.length > 0 ? row.order_items[0].product.title : ''}</p>)
      }, width: '250px'
    },

    {
      name: "Product Desc",
      cell: (row) => {
        let desc = row.order_items && row.order_items.length > 0 ? row.order_items[0].product.desc : '';
        // Truncate the description to 100 characters and add an ellipsis if necessary
        desc = desc.length > 70 ? `${desc.substring(0, 70)}...` : desc;
        //console.log(data);
        return (<p style={{ maxWidth: '450px', overflowWrap: 'break-word', textAlign: 'center', marginLeft:'20px', marginRight:'20px', marginTop: '5px', marginBottom: '5px' }}>{desc}</p>)
      }, width: '400px'
    },
    {
      name: "Vendor",
      cell: (row) => (
        <div className='d-flex items-center justify-center' >
          <ol>
            <ul>
              <li>
                <p>{row.shop && row.shop.business_name ? row.shop.business_name : ''}</p>
              </li>
              <li>
                <p>Contact: {row.shop && row.shop.phone ? row.shop.phone : ''}</p>
              </li>
            </ul>
          </ol>
        </div>
      ),
      minWidth: '300px'
    },
    {
      name: "Customer",
      cell: (row) => {
        //const vendorId = row["vendor_has_customer_id"]["customer_id"]["profile_id"]["name"];
        //console.log(row);
        return (
          <div className='d-flex justify-center items-center'>
            <ol>
              <ul>
                <li>
                  <p>Name: {row.profile && row.profile.name ? `${row.profile.name.first} ${row.profile.name.last}` : ''}</p>
                </li>
                <li>
                  <p>Phone: {row.profile && row.profile.phone ? row.profile.phone : " "}</p>
                </li>
              </ul>
            </ol>
          </div>
        );
      },
      minWidth: '350px'
    },
    {
      name: "Shipping Location",
      cell: (row) => {
        const shippingLocation = row["shipping_location"];
        return (
          <p>{shippingLocation?.city || "N/A"}, {row["shipping_location"]["state"] ? row["shipping_location"]["state"] : ''}, {row["shipping_location"]["country"] ? row["shipping_location"]["country"] : ''}</p>
        );
      },
      minWidth: '200px',
    },
    {
      name: "Shipping Charge",
      cell: (row) => (
        <ol>
          <ul>
            <li>
              <p>Type: {row["shipping_charge"]["type"] ? row["shipping_charge"]["type"] : ''}</p>
            </li>
            <li>
              <p>Amount: {row["shipping_charge"]["amount"] ? row["shipping_charge"]["amount"] : '0'}</p>
            </li>
          </ul>
        </ol>
      ),
      minWidth: '200px'
    },
    {
      name: "Product Charge",
      cell: (row) => (
        <ol>
          <ul>
            <li>
              <p>Type: {row["shipping_charge"]["type"] ? row["shipping_charge"]["type"] : ''}</p>
            </li>
            <li>
              <p>Amount: {row["shipping_charge"]["amount"] ? row["shipping_charge"]["amount"] : '0'}</p>
            </li>
          </ul>
        </ol>
      ),
      minWidth: '200px'
    },
    {
      name: "Total",
      cell: (data) => (
        <span>{data.total}</span>
      ),
      sortable: true,
      sortField: 'total'
    },
    {
      name: "Order Status",
      cell: (data) => {
        const type = data.status && data.status.length > 0 ? data.status[0].type : '';
        //const phone = data.vendor_has_customer_id?.store_id.phone || ''; // Assuming the customer's phone number is available in the data

        return (
          <div className='d-flex items-center'>
            <p className={`p-1 ${type === 'pending' ? 'pending' : ''}`}>{type}</p>
            {/* {type === 'pending' && phone && (
              <a href={generateWhatsAppUrl(phone)} target="_blank" rel="noopener noreferrer" className='p-2 ml-2'>
                <FaWhatsapp size={25} color="green" label="Notify?" />
              </a>
            )} */}
          </div>
        );
      },
      minWidth: '180px',
      sortable: true,
      sortFunction: this.customSort,
    },
    
   

    {
      name: "Last Updated On",
      cell: (data) => {
        const date = new Date(data.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'updatedAt',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (data) => (
        <div style={{ gap: "7px" }} className="d-flex">
          <ViewOrderButton
            handleDelete={() =>
              handleDelete(
                data["_id"],
                "Order",
                async () => {
                  const { currentPage, limit } = this.state;
                  const data = await getOrderTableConfig(currentPage, limit);
                  this.setState({ orderData: data.orders });
                }
              )
            }
          />
        </div>
      ),
    }
  ];
  
  render() {
    const { orderData, fetched, currentPage, search, limit} = this.state;
  
    const { history } = this.props;
    
    console.log("orderD:",orderData);
 

    

    return (
      <Container>
        {/* <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            Total OVERVIEW :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Store</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-secondary-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Catalogue</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Product</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Order</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            TODAY'S ORDER :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-secondary-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Accepted</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Shipped</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Delivered</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mb-3 d-flex mt-4 justify-content-start text-center">
        <div className="mx-2 mt-2"><h3>Orders</h3></div>
          <div className="col-12 col-xl-3 col-md-5">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search by business name"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>
          
          

          

        

        <div className="d-flex justify-content-between align-items-center">
         
            <div className="btn-group" role="group" aria-label="Order Status Filters">
              <button
                type="button"
                className={`btn ${this.state.filterStatus === 'null' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                onClick={() => this.setFilterStatus(null)}
              >
                All (0)
              </button>
            <button
              type="button"
                className={`btn ${this.state.filterStatus === 'pending' ? 'btn-warning' : 'btn-outline-secondary'}`}
              onClick={() => this.setFilterStatus('pending')}
            >
              Pending (30)
            </button>
            <button
              type="button"
              className={`btn ${this.state.filterStatus === 'shipped' ? 'btn-secondary' : 'btn-outline-secondary'}`}
              onClick={() => this.setFilterStatus('shipped')}
            >
              Shipped (0)
            </button>
            <button
              type="button"
                className={`btn ${this.state.filterStatus === 'accepted' ? 'btn-secondary' : 'btn-outline-secondary'}`}
              onClick={() => this.setFilterStatus('accepted')}
            >
              Accepted (0)
              </button>
              <button
                type="button"
                className={`btn ${this.state.filterStatus === 'delivered' ? 'btn-success' : 'btn-outline-secondary'}`}
                onClick={() => this.setFilterStatus('delivered')}
              >
                Delivered (0)
              </button>
              <button
                type="button"
                className={`btn ${this.state.filterStatus === 'cancelled' ? 'btn-danger' : 'btn-outline-secondary'}`}
                onClick={() => this.setFilterStatus('cancelled')}
              >
                Cancelled (0)
              </button>
              
          </div>
          </div>
        </div>
        <DataTable
          title=""
          // searchByList={[
          //   { label: "Order ID", value: "order_no" },
          //   {
          //     label: "Vendor Business Name",
          //     value: ({ vendor }) => (vendor ? vendor["business_name"] : ""),
          //   },
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ vendor }) => (vendor ? vendor["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getOrderTableConfig(history)}
        
       
          data={orderData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50]} // Options for rows per page
          paginationTotalRows={50} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default Order;
