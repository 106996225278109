import React, { Component } from "react";
import { handleDelete, wrapDataTable } from "../../../../utils";
import AddButton from "../../../common/AddButton";
import Container from "../../../common/Container";
import StatusSwitch from "../../../common/StatusSwitch";

const ViewSuggestedProductButton = ({ handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <button className="btn btn-primary p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

const SuggestedProductDataTable = wrapDataTable("SuggestedProduct");

class SuggestedProduct extends Component {
  render() {
    return (
      <Container>
        <SuggestedProductDataTable
          utilButtons={[
            <AddButton
              label="Suggstd Product"
              handler={() => this.props.history.push("/suggested/product/new")}
            />,
          ]}
          tableName="Suggested Products"
          searchByList={[
            { label: "Name", value: "title" },
            { label: "MRP", value: "mrp", exact: true },
            { label: "No of Unit", value: "unit_count", exact: true },
            { label: "Sale Price", value: "sales_price", exact: true },
          ]}
          columnData={[
            {
              name: "Photo",
              transform: (data) => (
                <img
                  src={
                    data && data["photo"]
                      ? data["photo"]
                      : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"
                  }
                  alt={data["name"]}
                />
              ),
              value: "photo",
            },
            { name: "Name", value: "name" },
            { name: "Catalog", transform: () => <div></div> },
            { name: "No of Unit", value: "unit_count" },
            { name: "Unit Type", value: "unit_type" },
            { name: "MRP", value: "mrp" },
            { name: "Product Details", value: "description" },
            {
              name: "Created on",
              transform: (data) =>
                new Date(data["createdAt"])
                  .toString()
                  .replace(/GMT[+A-Za-z0-9 ()]*/, ""),
              sortByFun: (a, b) =>
                new Date(a["createdAt"]) - new Date(b["createdAt"]),
            },
            {
              name: "Status",
              transform: (data) => (
                <StatusSwitch
                  data={data}
                  ofWhat="SuggestedProduct"
                  statusAttrName="isActive"
                />
              ),
            },
            {
              name: "View Product",
              transform: (data) => (
                <ViewSuggestedProductButton
                  handleEdit={() =>
                    this.props.history.push({
                      pathname: "/suggested/product/new",
                      state: {
                        id: data["_id"],
                        values: {
                          name: data["name"],
                          mrp: data["mrp"],
                          unit_type: data["unit_type"],
                          unit_count: data["unit_count"],
                          description: data["description"],
                          image: data["image"],
                          isActive: data["isActive"],
                        },
                      },
                    })
                  }
                  handleDelete={() =>
                    handleDelete(data && data["_id"], "SuggestedProduct")
                  }
                />
              ),
            },
          ]}
          defaultSortBy="Created on"
          defaultSortByFun={(a, b) =>
            new Date(a["createdAt"]) - new Date(b["createdAt"])
          }
        />
      </Container>
    );
  }
}

export default SuggestedProduct;
