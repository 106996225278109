import React, { Component } from "react";
import { handleDelete, wrapDataTable } from "../../../utils";
import AddButton from "../../common/AddButton";
import Container from "../../common/Container";
import StatusSwitch from "../../common/StatusSwitch";

const Actions = ({ handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <button className="btn btn-primary p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

const PincodeDataTable = wrapDataTable("Pincode");

class Pincode extends Component {
  render() {
    return (
      <Container>
        <PincodeDataTable
          utilButtons={[
            <AddButton
              label="New Pincode"
              handler={() => this.props.history.push("pincode/new")}
            />,
          ]}
          tableName="Pincode"
          searchByList={[
            { label: "Title", value: "title" },
            { label: "Description", value: "description" },
            {
              label: "Status",
              value: (data) => (data["status"] ? "Active" : "Inactive"),
            },
          ]}
          columnData={[
            { name: "Pincode", value: "pincode" },
            { name: "City", value: "city" },
            { name: "State", value: "state" },
            { name: "Country", value: "country" },
            {
              name: "Status",
              transform: (data) => (
                <StatusSwitch data={data} ofWhat="Pincode" statusAttrName="active" />
              ),
              value: "active",
            },
            {
              name: "Actions",
              transform: (data) => (
                <Actions
                  handleEdit={() => {
                    let partsOfDate = data.start.split("/");
                    const start = `${partsOfDate[2]}-${partsOfDate[1]}-${partsOfDate[0]}`;
                    partsOfDate = data.end.split("/");
                    const end = `${partsOfDate[2]}-${partsOfDate[1]}-${partsOfDate[0]}`;
                    this.props.history.push({
                      pathname: "/pincode/new",
                      state: {
                        id: data["_id"],
                        values: {
                          photo: data["photo"],
                          title: data["title"],
                          imgType: "url",
                          description: data["description"],
                          repeat: data["repeat"],
                          time: data["time"],
                          start: start,
                          end: end,
                        },
                      },
                    });
                  }}
                  handleDelete={() =>
                    handleDelete(data && data["_id"], "Pincode")
                  }
                  handleChangeStatus={() => null}
                />
              ),
            },
          ]}
          defaultSortBy="Created on"
          defaultSortByFun={(a, b) =>
            new Date(a["createdAt"]) - new Date(b["createdAt"])
          }
        />
      </Container>
    );
  }
}

export default Pincode;
