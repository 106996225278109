import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchPostList = createAsyncThunk("post/fetchList", async () => {
  try {
    const res = await UserService.get.PostList();
    return res.data && res.data.data;
  } catch (error) {
    console.log(error);
  }
});

const { reducer: PostReducer } = createSlice({
  name: "Post",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchPostList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default PostReducer;
