import React from "react";
import { useHistory } from "react-router";
import { handleDelete, wrapDataTable } from "../../../../utils";
import AddButton from "../../../common/AddButton";
import Container from "../../../common/Container";

const ViewSubCategoryButton = ({ handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <button className="btn btn-primary p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

const SubCategoryDataTable = wrapDataTable("SubCategory");

const SubCategory = () => {
  const history = useHistory();
  return (
    <Container>
      <SubCategoryDataTable
        utilButtons={[
          <AddButton
            label="New Sub Category"
            handler={() => history.push("/suggested/subcategory/new")}
          />,
        ]}
        tableName="Suggested Sub Categories"
        searchByList={[{ label: "Name", value: "name" }]}
        columnData={[
          {
            name: "Photo",
            transform: (data) => (
              <img
                src={
                  data && data["photo"]
                    ? data["photo"]
                    : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"
                }
                alt={data["name"]}
              />
            ),
            value: "photo",
          },
          { name: "Name", value: "name" },
          {
            name: "Action",
            transform: (data) => (
              <ViewSubCategoryButton
                handleEdit={() =>
                  history.push({
                    pathname: "/suggested/subcategory/new",
                    state: {
                      id: data["_id"],
                      values: {
                        name: data["name"],
                        photo: data["photo"],
                      },
                    },
                  })
                }
                handleDelete={() =>
                  handleDelete(data && data["_id"], "SubCategory")
                }
              />
            ),
          },
        ]}
        defaultSortByFun={(a, b) =>
          new Date(a["createdAt"]) - new Date(b["createdAt"])
        }
      />
    </Container>
  );
};

export default SubCategory;
