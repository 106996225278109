import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { fetchSubCategoryList } from "../../../../../redux/subCategorySlice";
import { photoSchema } from "../../../VendorCategory/NewVendorCategoryModal/ModalForm";

// Validation schema
const newSubCategorySchema = Yup.object().shape({
  name: Yup.string().required(),
});

export const ModalForm = withRouter(
  ({ submitHandler, onHide, preFill, history, catList }) => {
    const dispatch = useDispatch();
    const [categoryId, setCategoryid] = React.useState(
      catList[0] && catList[0]._id
    );
    const [catLabel, setCatLabel] = React.useState(
      preFill
        ? catList.length > 0 && catList.find(({ _id }) => _id === preFill.id)
        : catList[0] && catList[0].name
    );
    const handleCategoryChange = ({ target: { value } }) => {
      setCatLabel(value);
      setCategoryid(catList.find(({ name }) => name === value)._id);
    };
    return (
      <Formik
        enableReinitialize={true}
        initialValues={
          (preFill && preFill.values) || {
            name: "",
            photo: null,
          }
        }
        validationSchema={
          preFill
            ? newSubCategorySchema
            : newSubCategorySchema.clone(photoSchema)
        }
        onSubmit={async (values) => {
          values = { ...values, category_id: categoryId };
          const res = await (preFill
            ? submitHandler(preFill.id, values)
            : submitHandler(values));
          if (res.status === 200) {
            onHide();
            dispatch(fetchSubCategoryList());
          }
        }}
      >
        {({ values, handleSubmit, handleChange, isValid, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="justify-content-around row flex-column mb-0">
                  <div className="col d-flex flex-column align-items-center">
                    <img src={values.photo} alt="" width="80" />
                    <div className="form-group">
                      <label htmlFor="photo">Image</label>
                      <input
                        className="form-control-file"
                        type="file"
                        name="photo"
                        id="photo"
                        onChange={(event) => {
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  {/* Title */}
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="name">Title</label>
                      <input
                        className="form-control"
                        name="name"
                        placeholder="Title"
                        id="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label htmlFor="storyCategory">Sub Category</label>
                    <select
                      id="storyCategory"
                      className="text-capitalize form-control"
                      onChange={handleCategoryChange}
                      value={catLabel}
                    >
                      {catList.length > 0 &&
                        catList.map((cat) => (
                          <option value={cat.name} className="text-capitalize">
                            {cat.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-around">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
                disabled={!isValid}
              >
                {preFill ? "Save" : "Create"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    );
  }
);
