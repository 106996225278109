import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import Home from "./component/page/Home";
import Catalog from "./component/page/Catalog/Catalog";
import Product from "./component/page/Product";
import Order from "./component/page/Order";
import Post from "./component/page/Post/Post";
import Notification from "./component/page/Notification/Notification";
import Pincode from "./component/page/Pincode/Pincode";
import City from "./component/page/City/City";
import State from "./component/page/State/State";
import Country from "./component/page/Country/Country";
import Category from "./component/page/Category/Category";
import Story from "./component/page/Story/Story";
import SuggestedCategory from "./component/page/Suggested/Category/SuggestedCategory";
import SubCategory from "./component/page/Suggested/SubCategory/SubCategory";
import SuggestedProduct from "./component/page/Suggested/Product/SuggestedProduct";
import VendorList from "./component/page/Vendor/VendorList";
import VendorDetails from "./component/page/Vendor/VendorDetails";
import Customer from "./component/page/Customer/Customer";
import CustomerDetails from "./component/page/Customer/CustomerDetails";
import VendorCategory from "./component/page/VendorCategory/VendorCategory";
import Units from "./component/page/Units";
import Banner from "./component/page/Banner/BannerList";
import Basic from "./component/page/Settings/Basic";
import VendorCategoryDetails from "./component/page/VendorCategory/VendorCategoryDetails";

import { NewCategoryModal } from "./component/page/Category/NewCategoryModal/Modal";
import { NewStoryModal } from "./component/page/Story/NewStoryModal/Modal";
import { NewPostModal } from "./component/page/Post/NewPostModal/Modal";
import { NewCatalogModal } from "./component/page/Catalog/EditCatalogModal/Modal";
import { NewSuggestedCategoryModal } from "./component/page/Suggested/Category/NewSuggestedCategoryModal/Modal";
import { NewSubCategoryModal } from "./component/page/Suggested/SubCategory/NewSubCategoryModal/Modal";
import { NewSuggestedProductModal } from "./component/page/Suggested/Product/NewSuggestedProductModal/Modal";
import { ErrorPage } from "./component/page/ErrorPage/ErrorPage";
import { NewVendorCategoryModal } from "./component/page/VendorCategory/NewVendorCategoryModal/Modal";
import { NewSuggestedVendorModal } from "./component/page/Suggested/Vendor/NewSuggestedVendorModal/Modal/Modal";
import { ImportCSVVendorModal } from "./component/page/Suggested/Vendor/NewSuggestedImportModal/Modal";
import { NewUnitCategoryModal } from "./component/page/Suggested/Unit/NewSuggestedUnitCategoryModal/Modal";
import { NewBannerModal } from "./component/page/Banner/NewBannerModal/Modal";
import { NewNotificationModal } from "./component/page/Notification/NewNotificationModal/Modal";
import { NewPincodeModal } from "./component/page/Pincode/NewPincodeModal/Modal";
import { NewCityModal } from "./component/page/City/EditCityModal/Modal";
import { NewStateModal } from "./component/page/State/NewStateModal/Modal";
import { NewCountryModal } from "./component/page/Country/NewCountryModal/Modal";

class BasePage extends Component {
  
  render() {
    return (
      <>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/vendor" component={VendorList} />
          <Route exact path="/vendor/select">
            {({ history, match, location: { state } }) => (
              <ImportCSVVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route>
          <Route exact path="/vendor/new">
            {({ history, match, location: { state } }) => (
              <NewSuggestedVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route>
          <Route exact path="/vendor/:user_id" component={VendorDetails} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/banner" component={Banner} />
          <Route exact path="/customer" component={Customer} />
          <Route exact path="/customer/:cust_id" component={CustomerDetails} />
          <Route exact path="/basic" component={Basic} />
          <Route exact path="/vendor-category" component={VendorCategory} />
          <Route exact path="/vendor-category/new">
            {({ history, match, location: { state } }) => (
              <NewVendorCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor-category");
                }}
              />
            )}
          </Route>
          <Route exact path='/vendor-category/:user_id' component={VendorCategoryDetails} />
          
          <Route path="/units" component={Units} />
          <Route path="/catalog" component={Catalog} />
          <Route path="/notification" component={Notification} />
          <Route path="/pincode" component={Pincode} />
          <Route path="/city" component={City} />
          <Route path="/state" component={State} />
          <Route path="/country" component={Country} />
          <Route path="/post" component={Post} />
          <Route path="/category" component={Category} />
          <Route path="/story" component={Story} />
          <Route path="/banner/new" component={Banner} />
          <Route path="/vendor/new" component={VendorList} />
          <Route path="/suggested/category" component={SuggestedCategory} />
          <Route path="/suggested/subcategory" component={SubCategory} />
          <Route path="/suggested/product" component={SuggestedProduct} />
          <Route path="/" component={ErrorPage} />
          
        </Switch>
        <Switch>
          <Route exact path="/catalog/edit">
            {({ history, match, location: { state } }) => (
              <NewCatalogModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/catalog");
                }}
              />
            )}
          </Route>
          <Route exact path="/category/new">
            {({ history, match, location: { state } }) => (
              <NewCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/category");
                }}
              />
            )}
          </Route>
          <Route exact path="/suggested/category/new">
            {({ history, match, location: { state } }) => (
              <NewSuggestedCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/suggested/category");
                }}
              />
            )}
          </Route>
          <Route exact path="/suggested/product/new">
            {({ history, match, location: { state } }) => (
              <NewSuggestedProductModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/suggested/product");
                }}
              />
            )}
          </Route>
          <Route exact path="/suggested/subcategory/new">
            {({ history, match, location: { state } }) => (
              <NewSubCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/suggested/subcategory");
                }}
              />
            )}
          </Route>
          <Route exact path="/story/new">
            {({ history, match, location: { state } }) => (
              <NewStoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/story");
                }}
              />
            )}
          </Route>
          <Route exact path="/post/new">
            {({ history, match, location: { state } }) => (
              <NewPostModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/post");
                }}
              />
            )}
          </Route>
          <Route exact path="/vendor-category/new">
            {({ history, match, location: { state } }) => (
              <NewVendorCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor-category");
                }}
              />
            )}
          </Route>
          
          <Route exact path="/units/new">
            {({ history, match, location: { state } }) => (
              <NewUnitCategoryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/units");
                }}
              />
            )}
          </Route>
          {/* <Route exact path="/vendor/new">
            {({ history, match, location: { state } }) => (
              <NewSuggestedVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route> */}
          {/* <Route exact path="/vendor/select">
            {({ history, match, location: { state } }) => (
              <ImportCSVVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route> */}
          <Route exact path="/notification/new">
            {({ history, match, location: { state } }) => (
              <NewNotificationModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/notification");
                }}
              />
            )}
          </Route>


          <Route exact path="/pincode/new">
            {({ history, match, location: { state } }) => (
              <NewPincodeModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/pincode");
                }}
              />
            )}
          </Route>

          <Route exact path="/city/new">
            {({ history, match, location: { state } }) => (
              <NewCityModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/city");
                }}
              />
            )}
          </Route>

          <Route exact path="/state/new">
            {({ history, match, location: { state } }) => (
              <NewStateModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/state");
                }}
              />
            )}
          </Route>
          <Route exact path="/country/new">
            {({ history, match, location: { state } }) => (
              <NewCountryModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/country");
                }}
              />
            )}
          </Route>
          <Route exact path="/banner/new">
            {({ history, match, location: { state } }) => (
              <NewBannerModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/banner");
                }}
              />
            )}
          </Route>
          {/* <Route exact path="/vendor/new">
            {({ history, match, location: { state } }) => (
              <NewSuggestedVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route> */}

          {/* <Route exact path="/vendor/select">
            {({ history, match, location: { state } }) => (
              <ImportCSVVendorModal
                preFill={state}
                show={match != null}
                onHide={() => {
                  history.push("/vendor");
                }}
              />
            )}
          </Route> */}



        </Switch>
      </>
    );
  }
}

export default BasePage;
