import { configureStore } from "@reduxjs/toolkit";
import CatalogReducer, { fetchCatalogList } from "./catalogSlice";
import VendorReducer, { fetchVendorList } from "./vendorSlice";
import ProductReducer, { fetchProductList } from "./productSlice";
import OrderReducer, { fetchOrderList } from "./orderSlice";
import StoryReducer, { fetchStoryList } from "./storySlice";
import PostReducer, { fetchPostList } from "./postSlice";
import CategoryReducer, { fetchCategoryList } from "./categorySlice";
import NotificationReducer, { fetchNotificationList } from "./notificationSlice";
import PincodeReducer, { fetchPincodeList } from "./pincodeSlice";
import CityReducer, { fetchCityList } from "./citySlice";
import StateReducer, { fetchStateList } from "./stateSlice";
import CountryReducer, { fetchCountryList } from "./countrySlice";
import SubCategoryReducer, { fetchSubCategoryList } from "./subCategorySlice";
import CustomerReducer, { fetchCustomerList } from "./customerSlice";
import bannerReducer, { fetchBannerList } from "./bannerSlice";

import SuggestedCategoryReducer, {
  fetchSuggestedCategoryList,
} from "./suggestedCategorySlice";
import VendorCategoryReducer, {
  fetchVendorCategoryList,
} from "./vendorCatgorySlice";
import SuggestedProductReducer, {
  fetchSuggestedProductList,
} from "./suggestedProductSlice";

export const AsyncThunks = {
  fetchVendorList,
  fetchCatalogList,
  fetchProductList,
  fetchOrderList,
  fetchStoryList,
  fetchPostList,
  fetchCategoryList,
  fetchSuggestedCategoryList,
  fetchSubCategoryList,
  fetchSuggestedProductList,
  fetchCustomerList,
  fetchVendorCategoryList,
  fetchNotificationList,
  fetchPincodeList,
  fetchCityList,
  fetchStateList,
  fetchCountryList,
  fetchBannerList,
};

export default configureStore({
  reducer: {
    VendorReducer,
    CatalogReducer,
    ProductReducer,
    OrderReducer,
    StoryReducer,
    PostReducer,
    CategoryReducer,
    SuggestedCategoryReducer,
    SubCategoryReducer,
    SuggestedProductReducer,
    CustomerReducer,
    VendorCategoryReducer,
    NotificationReducer,
    PincodeReducer,
    CityReducer,
    StateReducer,
    CountryReducer,
    bannerReducer,
  },
});
