import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import UserService from "../../services/user.service";
import ReactApexChart from "react-apexcharts";
import { checkAuthed, getLast7Days, formatDateToYYYYMMDD } from "../../utils";
import authHeader from "../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../utils";



const pieChartOptions = {
  chart: {
    type: "pie",
    height: 330,
  },
  labels: ["Customers", "Orders", "Vendors", "Products"],
};

const chartOptions = {
  chart: {
    height: 330,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: [4, 4],
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 0.5,
    },
  },
  markers: {
    size: 5,
  },
  xaxis: {
    categories: getLast7Days(),
  },
};

const getDashboarddata = async () => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}dashboard/admin/count`, {

      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return null;
  }
};


//const response=getDashboarddata();



class Home extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);


    
    
    this.state = {
      name: "",
      fromDate: today.toISOString().split('T')[0],
      endDate: tomorrow.toISOString().split('T')[0],
      customerCount: 0,
      orderCount: 0,
      vendorCount: 0,
      catalogCount: 0,
      productCount: 0,
      cartCount: 0,
      wishlistCount: 0,
      totalcustomercount: 0,
      totalvendorCount: 0,
      totalproductCount: 0,
      totalorderCount: 0,
      totalcatalogCount: 0,
      totalcartCount: 0,
      totalwishlistCount: 0,
      totalAcceptedCount: 0,
      totalShippedCount: 0,
      totalDeliveredCount: 0,
      acceptedOrderCout: 0,
      shippedOrderCount: 0,
      deliveredOrderCount: 0,
      pieChartData: [0, 0, 0, 0],
      chartData: [
        {
          name: "Customers",
          data: [0,0,0,0,0,0],
        },
        {
          name: "Carts",
          data: [0,0,0,0,0,0],
        },
        {
          name: "Orders",
          data: [0,0,0,0,0,0]
        }
      ],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeFromDate = this.onChangeFromDate.bind(this);
    this.onChangeToDate = this.onChangeToDate.bind(this);

  }

  async componentDidMount() {
    const x = checkAuthed(this.props.history);
    const { data } = JSON.parse(x);
    this.setState({ name: data.full_name });

    const dashboardData = await getDashboarddata();
    //console.log(dashboardData);
    if (dashboardData) {
      // Assuming dashboardData contains the counts for vendor, product, order, and catalog
      this.setState({
        totalvendorCount: dashboardData.total_shop,
        totalproductCount: dashboardData.total_product,
        totalorderCount: dashboardData.total_order,
        totalcatalogCount: dashboardData.total_catalog,
        totalcustomerCount: dashboardData.total_customer,
        totalcartCount: dashboardData.total_cart,
        totalwishlistCount: dashboardData.total_wishlist,
        pieChartData: [
          dashboardData.total_customer,
          dashboardData.total_order,
          dashboardData.total_shop,
          dashboardData.total_product
        ],
        chartData: [
          {
            name: "Customers",
            data: dashboardData.total_customer,
          },
          {
            name: "Carts",
            data: dashboardData.total_cart,
          },
          {
            name: "Orders",
            data: dashboardData.total_order
          }
        ],
      });
    }

    // await UserService.get.DashboardTotalCount()
    //   .then((result) => {
    //     console.log("total",result);
    //     this.setState({
    //       totalvendorCount: result.data.total_shop,
    //       totalproductCount: result.data.total_product,
    //       totalorderCount: result.data.total_order,
    //       totalcatalogCount: result.data.total_catalog,
    //       // totalcustomerCount: result.data.data.customer,
    //       // totalwishlistCount: result.data.data.wishlist,
    //       // totalcartCount: result.data.data.cart
    //     });
    //   })
    //   .catch((error) => console.error(error));

    try{
    const urlToday = `${API_URL}/dashboard/admin/count?startDate=${this.state.fromDate}&endDate=${this.state.endDate}`;
    const result = await axios.get(urlToday, {
      headers: {
        ...authHeader(),
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    console.log("today", result);
    this.setState({
      vendorCount: result.data.total_shop,
      productCount: result.data.total_product,
      orderCount: result.data.total_order,
      catalogCount: result.data.total_catalog,
      totalAcceptedCount: result.data.total_order_status.accepted,
      totalShippedCount: result.data.total_order_status.shipped,
      totalDeliveredCount: result.data.total_order_status.delivered,
      acceptedOrderCout: result.data.total_order_status.accepted,
      shippedOrderCount: result.data.total_order_status.shipped,
      deliveredOrderCount: result.data.total_order_status.delivered,
      // customerCount: result.data.data.customer,
      // cartCount: result.data.data.cart,
      // wishlistCount: result.data.data.wishlist
    });
  } catch(error) {
    console.error("Error fetching today's dashboard data:", error);
    }
    
    this.fetchDataforGraph();

    // TODO: getDashboardLast7DaysCount
  }

  fetchDataforGraph = async () => {
    const today = new Date();
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date);
    }

    const formattedDates = dates.map(date => {
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-based
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    });
    console.log("formatted",formattedDates);

    try {
      const dataPromises = formattedDates.map(date => {
        // Convert the date string to a Date object
        const startingDate = new Date(date);

        // Add one day to get the next date
        const endDate = new Date(startingDate);
        endDate.setDate(startingDate.getDate() + 1);

        // Format the dates back to the desired format (assuming ISO string here)
        const startDateString = startingDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const endDateString = endDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD

        // Make the API request with headers and other settings
        return axios.get(`${API_URL}/dashboard/admin/count`, {
          params: {
            startDate: startDateString,
            endDate: endDateString
          },
          headers: {
            ...authHeader(),
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
          },
          mode: 'cors',
          cache: 'default'
        });
      });

      const dataResponses = await Promise.all(dataPromises);
      console.log("dataaaa",dataResponses);

      const orderCountsGraph = dataResponses.map(response => response.data.total_order);
      console.log("ordercountsss", orderCountsGraph);
      const cartCountsGraph = dataResponses.map(response => response.data.total_cart);
      console.log("cartsss", cartCountsGraph);
      const custCountsGraph = dataResponses.map(response => response.data.total_customer);
      console.log("cartsss", custCountsGraph);


      this.setState({
        orderCount: dataResponses[0].data.total_order,
        cartCount: dataResponses[0].data.total_cart,
        custCount: dataResponses[0].data.total_customer,
        
        chartData: [{
          name: 'Carts',
          data: cartCountsGraph.reverse() // Reverse to show the oldest date first
        },
          {
            name: 'Customers',
            data: custCountsGraph.reverse()
          },
        {
          name: 'Orders',
          data: orderCountsGraph.reverse()
        }],
        chartOptions: {
          ...this.state.chartOptions,
          xaxis: {
            categories: formattedDates.reverse() // Reverse to match order of data
          }
        }
      });
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }

  onChangeFromDate(e) {
    this.setState({
      fromDate: e.target.value,
    });
  }

  onChangeToDate(e) {
    this.setState({
      endDate: e.target.value,
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const { fromDate, endDate } = this.state;
    //console.log(fromDate);

    try {
      // Construct the complete URL with query parameters
      const url = `${API_URL}/dashboard/admin/count?startDate=${fromDate}&endDate=${endDate}`;

      
      const response = await axios.get(url, {
        headers: {
          ...authHeader(), 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0' 
        },
        mode: "cors",
        cache: 'default'
      });

      console.log("Response from server:", response.data);

      const { total_shop, total_product, total_order, total_catalog, total_customer,total_cart,total_wishlist } = response.data;

      const { total_order_status_accepted,total_order_status_shipped,total_order_status_delivered } = response.data.total_order_status;

      this.setState({
        vendorCount: total_shop,
        productCount: total_product,
        orderCount: total_order,
        catalogCount: total_catalog,
        customerCount: total_customer,
        cartCount: total_cart,
        wishlistCount: total_wishlist,
        acceptedOrderCout: total_order_status_accepted,
        shippedOrderCount: total_order_status_shipped,
        deliveredOrderCount: total_order_status_delivered,

        pieChartData: [
          total_customer,
          total_order,
          total_shop,
          total_product,
        ],
        
      });

      console.log("Updated State:", this.state);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
    }
  }

  render() {
    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          {/* <SettingPanel />
          <RightSidebar /> */}
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              

              <div className="row m-0">
                <div className="col-md-12 grid-margin transparent">
                  <form onSubmit={this.onSubmit}>
                    <div className="mb-5">
                      <div className="d-flex justify-content-end align-items-end flex-wrap">
                        <div className="d-flex flex-column flex-md-row align-items-start mb-md-0">
                          <div className="mr-md-3">
                            <label htmlFor="fromDate" className="font-weight-bold text-muted text-center">Start Date</label>
                            <input
                              id="fromDate"
                              name="fromDate"
                              type="date"
                              className="form-control mt-1"
                              onChange={this.onChangeFromDate}
                              required
                            />
                          </div>
                          <div className="ml-md-3 mt-3 mt-md-0">
                            <label htmlFor="endDate" className="font-weight-bold text-muted text-center">End Date</label>
                            <input
                              id="endDate"
                              name="endDate"
                              type="date"
                              className="form-control mt-1"
                              onChange={this.onChangeToDate.bind(this)}
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-end mt-3 mt-md-0 ml-4">
                          <button
                            type="submit"
                            className="btn btn-dark"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>

                  </form>

                  <div className="row">
                    <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
                      OVERVIEW :
                    </h3>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-tale">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalvendorCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Store
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "20px",
                                marginRight: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.vendorCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-dark-blue">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalcatalogCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Catalogue
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.catalogCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-light-danger">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalproductCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Product
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "20px",
                                marginRight: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.productCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-light-blue">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalcustomerCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Customers
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "25px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.customerCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
                      ORDER :
                    </h3>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-tale">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalorderCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Orders
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "20px",
                                marginRight: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.orderCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-dark-blue">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalAcceptedCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Accepted
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.acceptedOrderCout}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-light-danger">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalShippedCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Shipped
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.shippedOrderCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-light-blue">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalDeliveredCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Delivered
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.deliveredOrderCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
                      OTHERS :
                    </h3>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-tale">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalcartCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Cart
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "20px",
                                marginRight: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.cartCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
                      <div className="card card-dark-blue">
                        <div className="card-body d-flex justify-content-between">
                          <div>
                            <h5 className="mb-4 font-larger">Total</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.totalwishlistCount}
                            </p>
                            {/* <p>10.00%</p> */}
                          </div>

                          <div>
                            <h5 className="mb-2 font-larger font-bold">
                              Wishlist
                            </h5>
                            <div
                              style={{
                                borderLeft: "2px solid white",
                                marginLeft: "30px",
                                marginRight: "30px",
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2px",
                                marginBottom: "0px",
                                height: "30px",
                              }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <h5 className="mb-4 font-larger">Today</h5>
                            <p style={{ fontSize: "30px" }}>
                              {this.state.wishlistCount}
                            </p>
                            {/* <p>10.00% </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>


                </div>
              </div>
              <div className="row">
                <h3 className="col-12 font-weight-bold mb-3 text-uppercase">
                Orders, Carts and Customers over the days
                </h3>
                <div className="col-lg-10 col-sm-6">
                  <ReactApexChart
                    options={chartOptions}
                    series={this.state.chartData}
                    type="line"
                    height={350}
                  />
                </div>
              </div>
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

