import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../../../services/user.service";
import { ModalForm } from "./ModalForm";

export function NewSuggestedCategoryModal({ show, onHide, preFill }) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {preFill ? "Edit" : "Add New"} Suggested Category
        </Modal.Title>
      </Modal.Header>
      <ModalForm
        onHide={onHide}
        preFill={preFill}
        submitHandler={UserService[preFill ? "put" : "post"].SuggestedCategory}
      />
    </Modal>
  );
}
