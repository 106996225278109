import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../utils";
import Container from "../common/Container";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { API_URL } from "../../utils";
import _ from 'lodash';
import AddButton from '../common/AddButton';

const ViewOrderButton = ({ handleDelete }) => (
  <div className="container">
    <div className="d-flex justify-center" >
      <button className="btn btn-danger p-2" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);



export const getOrderTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}order/admin`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], totalCount: 0 };
  }
};

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    this.fetchData(currentPage, limit, search, sort, sortBy);
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getOrderTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetched", data);
      this.setState({ orderData: data, totalRows: data.totalCount, fetched: true });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  handleSearchChange = (e) => {
    let search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  }

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      //console.log(limit);
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  columns = [
    { name: "SL", cell: (row, index) => <p>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },
    { name: "Order ID", cell: (data) => <p>{data["_id"]}</p>, width: '250px' },
    { name: "Order At", cell: (data) => <p>{data["_id"]}</p>, width: '250px' },
    {
      name: "Product Info", cell: (data) => {
        //console.log(data);
        return (<p>{data["_id"]}</p>)
      }, width: '250px'
    },
    {
      name: "Vendor",
      cell: (row) => (
        <div className='d-flex items-center justify-center' >
          <ol>
            <ul>
              <li>
                <p>{row["vendor_has_customer_id"]["store_id"]["business_name"]}</p>
              </li>
              <li>
                <p>Contact: {row["vendor_has_customer_id"]["store_id"]["phone"]}</p>
              </li>
            </ul>
          </ol>
        </div>
      ),
      minWidth: '300px'
    },
    {
      name: "Customer",
      cell: (row) => {
        const vendorId = row["vendor_has_customer_id"]["customer_id"]["profile_id"]["name"];
        //console.log(row);
        return (
          <ol>
            <ul>
              <li>
                <p>Name: {vendorId?.first || "N/A"}  {vendorId?.last || "N/A"}</p>
              </li>
              <li>
                <p>Phone: {vendorId?.phone || "N/A"}</p>
              </li>
            </ul>
          </ol>
        );
      },
      minWidth: '200px'
    },
    {
      name: "Shipping Location",
      cell: (row) => {
        const shippingLocation = row["shipping_location"];
        return (
          <p>{shippingLocation?.city || "N/A"}, {row["shipping_location"]["state"]}, {row["shipping_location"]["country"]}</p>
        );
      },
      minWidth: '200px',
    },
    {
      name: "Shipping Charge",
      cell: (row) => (
        <ol>
          <ul>
            <li>
              <p>Type: {row["shipping_charge"]["type"]}</p>
            </li>
            <li>
              <p>Amount: {row["shipping_charge"]["amount"]}</p>
            </li>
          </ul>
        </ol>
      ),
      minWidth: '200px'
    },
    {
      name: "Product Charge",
      cell: (row) => (
        <ol>
          <ul>
            <li>
              <p>Type: {row["shipping_charge"]["type"]}</p>
            </li>
            <li>
              <p>Amount: {row["shipping_charge"]["amount"]}</p>
            </li>
          </ul>
        </ol>
      ),
      minWidth: '200px'
    },
    {
      name: "Order Status",
      cell: (data) => (
        <div className='pending p-1 rounded-1' >
          <p>{data["status"][0]["type"]}</p>
        </div>
      ),
      minWidth: '150px'
    },
    {
      name: "Total",
      cell: (data) => (
        <span>{data.total}</span>
      )
    },
    {
      name: "Created On",
      cell: (data) => {
        const date = new Date(data.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'createdAt',
      sortable: true,
    },

    {
      name: "Updated On",
      cell: (data) => {
        const date = new Date(data.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'updatedAt',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (data) => (
        <div style={{ gap: "7px" }} className="d-flex">
          <ViewOrderButton
            handleDelete={() =>
              handleDelete(
                data["_id"],
                "Order",
                async () => {
                  const { currentPage, limit } = this.state;
                  const data = await getOrderTableConfig(currentPage, limit);
                  this.setState({ orderData: data.orders });
                }
              )
            }
          />
        </div>
      ),
    }
  ];

  render() {
    const { orderData, fetched, currentPage, search, limit } = this.state;

    const { history } = this.props;

    console.log(orderData);




    return (
      <Container>
        {/* <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            Total OVERVIEW :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Store</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-dark-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Catalogue</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Product</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Order</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            TODAY'S ORDER :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-dark-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Accepted</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Shipped</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Delivered</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mb-3 d-flex mt-4 justify-content-start text-center">

          <div className="col-12 col-xl-6 col-md-5">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>

          <AddButton label="Add New Unit" handler={() => history.push('units/new')} />
        </div>
        <DataTable
          title="Units"
          // searchByList={[
          //   { label: "Order ID", value: "order_no" },
          //   {
          //     label: "Vendor Business Name",
          //     value: ({ vendor }) => (vendor ? vendor["business_name"] : ""),
          //   },
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ vendor }) => (vendor ? vendor["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getOrderTableConfig(history)}


          data={orderData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50]} // Options for rows per page
          paginationTotalRows={30} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default Order;
