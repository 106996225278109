import axios from "axios";
import { API_URL } from "../utils";
import authHeader from "./auth-header";

function API(version = "", api) {
  const wrapper = (version.length > 0 ? version + "/" : "") + api + "/";
  return {
    get: async (url = "", options = {}) =>
      await axios.get(API_URL + url, {
        headers: {
          ...authHeader(),
          ...options,
        },
      }),
    post: {
      JSON: async (url = "", body, options = {}) =>
        await axios.post(API_URL + wrapper + url, body, {
          headers: {
            ...authHeader(),
            "Content-Type": "application/json",
            ...options,
          },
        }),
      FormData: async (url = "", body, options = {}) => {
        try {
          console.log("body",body);
          const formData = new FormData();
          if (body && typeof body === 'object') {
            for (let key in body) {
              if (body.hasOwnProperty(key)) {
                formData.append(key, body[key]);
                console.log("key",key,body[key]);
              }
              
            }
          }
          console.log("formdaat",formData);
          const fullUrl = API_URL + url;
          console.log("url",fullUrl);
          const response = await axios.post(API_URL + url, formData, {
            headers: {
              ...authHeader(),
              ...options,
              "Content-Type": "multipart/form-data",
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDBmNDdlNDU5NDliZmFkYTVjZDNkNTEiLCJpYXQiOjE3MTY3OTAxMjQsImV4cCI6MTc0ODMyNjEyNH0.otLzAFe7Ba8Qy8K7CNFTpajda7Yqwm23adJEvqu4vJs'
            },
          });
          console.log("resp",response);
          return response;
        } catch (error) {
          console.error("Error in FormData post request:", error);
          throw error;
        }
      },
    },
    put: {
      JSON: async (url = "", body, options = {}) =>
        await axios.put(API_URL + wrapper + url, body, {
          headers: {
            ...authHeader(),
            "Content-Type": "application/json",
            ...options,
          },
        }),
      FormData: async (url = "", body, options = {}) => {
        console.log("body");
        const formData = new FormData();
        for (let key in body) {
          formData.append(key, body[key]);
          
        }
        
        return await axios.put(API_URL + wrapper + url, formData, {
          headers: {
            ...authHeader(),
            ...options,
          },
        });
      },
    },
    delete: async (url = "", options = {}) =>
      await axios.delete(API_URL + wrapper + url, {
        headers: {
          ...authHeader(),
          ...options,
        },
      }),
  };
  
}


const UserService = {
  get: (() => {
    const reqs = [
      { name: "DashboardTodayCount", url: "dashboard/admin/count" },
      { name: "DashboardTotalCount", url: "dashboard/admin/count"},
      { name: "VendorList", url: "shop/admin/v1" },
      { name: "CatalogList", url: "catalog/admin" },
      { name: "ProductList", url: "product/admin" },
      { name: "OrderList", url: "order/list" },
      { name: "PostList", url: "posts", version: "v1" },
      { name: "StoryList", url: "stories", version: "v1" },
      { name: "CategoryList", url: "category", version: "v1" },
      { name: "CategoryListByType", url: "category", version: "v1" },
      { name: "SuggestedCategoryList", url: "suggestedCategory" },
      { name: "SubCategoryList", url: "subCategory" },
      { name: "SubCategoryInCategory", url: "subCategory_in_category" },
      { name: "SuggestedProduct", url: "suggestedProduct" },
      {
        name: "VendorDetails",
        url: "vendor",
        transform: (res) => (res.status === 200 ? res.data.data : {}),
      },
      {
        name: "VendorCatalogs",
        url: "catalog/list",
        transform: (res) => (res.status === 200 ? res.data.data : []),
      },
      {
        name: "VendorProducts",
        url: "product/list",
        transform: (res) => (res.status === 200 ? res.data.data : []),
      },
      {
        name: "VendorOrders",
        url: "order/list_vendor",
        transform: (res) => (res.status === 200 ? res.data.data : []),
      },
      {
        name: "VendorSettings",
        url: "vendor/setting",
        transform: (res) => (res.status === 200 ? res.data.data : {}),
      },
      {
        name: "CustomerList",
        url: "customer/list",
        transform: (res) => (res.status === 200 ? res.data.data : {}),
      },
      {
        name: "CustomerDetails",
        url: "customer/list",
        transform: (res) => (res.status === 200 ? res.data.data : {}),
      },
      {
        name: "CustomerOrders",
        url: "order/list",
        transform: (res) => (res.status === 200 ? res.data.data : {}),
      },
      { name: "VendorCategory", url: "shop_category" },
      { name: "NotificationList", api: "notification" },
    ];
    const get = {};
    for (const req of reqs) {
      get[req.name] = req.transform
        ? async (param = "") => {
          const res = await API(req.version || "", req.api || "admin").get(
            (req.url ? req.url + "/" : "") + param
          );
          console.log(param);
          return req.transform(res);
        }
        : async (param = "") =>
          await API(req.version || "", req.api || "admin").get(
            (req.url ? req.url + "/" : "") + param
          );
    }
    return get;
  })(),

  delete: (() => {
    const reqs = [
      { name: "VendorCategory", url: "deleteCategory" },
      { name: "Vendor", url: "vendor" },
      { name: "Customer", url: "customer" },
      { name: "Order", url: "order" },
      { name: "Catalog", url: "vendor/catalog_delete" },
      { name: "Story", url: "stories", version: "v1" },
      { name: "Post", url: "posts", version: "v1" },
      { name: "Product", url: "vendor/product_delete" },
      { name: "Category", url: "category", version: "v1" },
      { name: "SuggestedCategory", url: "suggestedCategory" },
      { name: "SubCategory", url: "subCategory" },
      { name: "SuggestedProduct", url: "suggestedProduct" },
      { name: "Notification", api: "notification" },
    ];
    const del = {};
    for (const req of reqs) {
      del[req.name] = req.transform
        ? async (param = "") => {
          const res = await API(req.version || "", req.api || "admin").delete(
            (req.url ? req.url + "/" : "") + param
          );
          return req.transform(res);
        }
        : async (param = "") =>
          await API(req.version || "", req.api || "admin").delete(
            (req.url ? req.url + "/" : "") + param
          );
    }
    return del;
  })(),

  post: (() => {
    const reqs = [
      { name: "FilterCount", url: "filter/count", type: "JSON" },
      { name: "Category", url: "category", type: "JSON", version: "v1" },
      { name: "VendorCategory", url: "shop_category", type: "FormData" },
      { name: "Post", url: "posts", type: "FormData", version: "v1" },
      { name: "Story", url: "stories", type: "FormData", version: "v1" },
      { name: "SuggestedCategory", url: "suggestedCategory", type: "FormData" },
      { name: "SubCategory", url: "subCategory", type: "FormData" },
      { name: "SuggestedProduct", url: "suggestedProduct", type: "FormData" },
      { name: "Notification", api: "notification", type: "FormData" },

    ];
    const post = {};
    for (const req of reqs) {
      post[req.name] = req.transform
        ? async (payload, param = "") => {
          const res = await API(req.version || "", req.api || "admin").post[
            req.type
          ]((req.url ? req.url + "/" : "") + param, payload);
          return req.transform(res);
        }
        : async (payload, param = "") =>
          await API(req.version || "", req.api || "admin").post[req.type](
            (req.url ? req.url + "/" : "") + param,
            payload
          );
    }
    return post;
  })(),

  put: (() => {
    const reqs = [
      { name: "Category", url: "category", version: "v1", type: "JSON" },
      { name: "Post", url: "posts", version: "v1", type: "FormData" },
      { name: "Story", url: "stories", version: "v1", type: "FormData" },
      { name: "Catalog", url: "catalog", type: "FormData" },
      { name: "SuggestedCategory", url: "suggestedCategory", type: "FormData" },
      { name: "SubCategory", url: "subCategory", type: "FormData" },
      { name: "SuggestedProduct", url: "suggestedProduct", type: "FormData" },
      { name: "VendorSettings", url: "vendor/setting", type: "JSON" },
      { name: "Customer", url: "customer", type: "FormData" },
      {
        name: "VendorCategory",
        url: "shop_category",
        type: "FormData",
      },
      { name: "Notification", api: "notification", type: "FormData" },
    ];
    const put = {};
    for (const req of reqs) {
      put[req.name] = req.transform
        ? async (param = "", payload) => {
          const res = await API(req.version || "", req.api || "admin").put[
            req.type
          ]((req.url ? req.url + "/" : "") + param, payload);
          return req.transform(res);
        }
        : async (param = "", payload) =>
          await API(req.version || "", req.api || "admin").put[req.type](
            (req.url ? req.url + "/" : "") + param,
            payload
          );
    }
    return put;
  })(),
};

export default UserService;
