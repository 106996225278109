import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchBannerList } from "../../../../redux/bannerSlice"

// Validation schema
const bannerSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is required"),
    bannerName: Yup.string().required("Banner Name is required"),
    bannerURL: Yup.string().url("Invalid URL").required("Banner URL is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    status: Yup.string().required("Status is required"),
});

export const ModalForm = ({ submitHandler, actionsLoading, onHide, preFill }) => {
    const dispatch = useDispatch();

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                image: preFill ? preFill.image : null,
                bannerName: preFill ? preFill.bannerName : "",
                bannerURL: preFill ? preFill.bannerURL : "",
                startDate: preFill ? preFill.startDate : "",
                endDate: preFill ? preFill.endDate : "",
                status: preFill ? preFill.status : "active", // Assuming default value for status
            }}
            validationSchema={bannerSchema}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <>
                    {(() => console.log(values))()}
                    <Modal.Body className="overlay overlay-block cursor-default">
                        <Form className="form form-label-right">
                            <div className="form-group">
                                <label htmlFor="image">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(event) => {
                                        setFieldValue("image", event.currentTarget.files[0]);
                                    }}
                                />
                                <ErrorMessage name="image" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerName">Banner Name</label>
                                <Field type="text" name="bannerName" className="form-control" />
                                <ErrorMessage name="bannerName" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerURL">Banner URL</label>
                                <Field type="text" name="bannerURL" className="form-control" />
                                <ErrorMessage name="bannerURL" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="startDate">Start Date</label>
                                <Field type="date" name="startDate" className="form-control" />
                                <ErrorMessage name="startDate" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="endDate">End Date</label>
                                <Field type="date" name="endDate" className="form-control" />
                                <ErrorMessage name="endDate" component="div" className="text-danger" />
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <div className="active-inactive-toggle-">
                                    <label className="active-inactive-toggle">
                                        <Field type="radio" name="status" value="active" />
                                        Active
                                    </label>
                                    <label className="active-inactive-toggle">
                                        <Field type="radio" name="status" value="inactive" />
                                        Inactive
                                    </label>
                                </div>
                                <ErrorMessage name="status" component="div" className="text-danger" />
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={actionsLoading}>
                            {preFill ? "Save" : "Create"}
                        </button>
                    </Modal.Footer>
                </>
            )}
        </Formik>
    );
};
