import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user.service";

export const fetchStoryList = createAsyncThunk("story/fetchList", async () => {
  try {
    const res = await UserService.get.StoryList();
    return res.data && res.data.data;
  } catch (error) {
    console.log(error);
  }
});

const { reducer: StoryReducer } = createSlice({
  name: "Story",
  initialState: {
    dataList: [],
    fetched: false,
  },
  extraReducers: {
    [fetchStoryList.fulfilled]: (state, action) => {
      state.dataList = action.payload;
      state.fetched = true;
      return state;
    },
  },
});

export default StoryReducer;
