import React from "react";
import { useHistory } from "react-router";
import { handleDelete, wrapDataTable } from "../../../../utils";
import AddButton from "../../../common/AddButton";
import Container from "../../../common/Container";
import { CKEditor } from 'ckeditor4-react';
import { useState } from "react";
const SuggestedCategory = () => {
  const [terms, setTerms] = useState('');
  const [items, setItems] = useState('');
  const history = useHistory();
  const [policy, setPolicy] = useState('');
  const [about, setAbout] = useState('');
  

  const handleEditorChange = (event) => {
    setTerms(event.editor.getData());
    setPolicy(event.editor.getData());
    setItems(event.editor.getData());
    setAbout(event.editor.getData());    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Terms and Conditions:', terms);
  };


  return (
    <Container>
    <div class="md:container md:mx-auto">
      
    <div class="container">


{/* <nav aria-label="breadcrumb" class="main-breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a id="a" href="index.html">Dashboard</a></li>
    <li class="breadcrumb-item"><a id="a" href="javascript:void(0)">Setting</a></li>
    <li class="breadcrumb-item active" aria-current="page">Policy</li>
  </ol>
</nav> */}


<div class="row gutters-sm">
  <div class="col-md-4 d-none d-md-block">
    <div class="card">
      <div class="card-body">
        <nav class="nav flex-column nav-pills nav-gap-y-1">
          <a id="a" href="#profile" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded active">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>About Us
          </a>
          <a id="a" href="#account" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings mr-2"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>Terms & Condition 
          </a>
          <a id="a" href="#security" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield mr-2"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>Privacy policy 
          </a>
          <a id="a" href="#notification" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell mr-2"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>Shipping Policy 
          </a>
          <a id="a" href="#billing" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card mr-2"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>Refund policy 
                    </a>
                    <a id="a" href="#banned" data-toggle="tab" class="nav-item nav-link has-icon nav-link-faded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card mr-2"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>Banned Items
                    </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="card">
      <div class="card-header border-bottom mb-3 d-flex d-md-none">
        <ul class="nav nav-tabs card-header-tabs nav-gap-x-1" role="tablist">
          <li class="nav-item">
            <a id="a" href="#profile" data-toggle="tab" class="nav-link has-icon active"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></a>
          </li>
          <li class="nav-item">
            <a id="a" href="#account" data-toggle="tab" class="nav-link has-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></a>
          </li>
          <li class="nav-item">
            <a id="a" href="#security" data-toggle="tab" class="nav-link has-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg></a>
          </li>
          <li class="nav-item">
            <a id="a" href="#notification" data-toggle="tab" class="nav-link has-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg></a>
          </li>
          <li class="nav-item">
            <a id="a" href="#billing" data-toggle="tab" class="nav-link has-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg></a>
          </li>
        </ul>
      </div>
      <div class="card-body tab-content">
        <div class="tab-pane active" id="profile">
                    <h6 className="text-black text-xl font-semibold mb-4">ABOUT US</h6>
                    <hr className="mb-6" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-6">
                        <label htmlFor="about" className="block text-gray-700 font-medium mb-2">Edit About Us</label>
                        <CKEditor
                          initData={about}
                          onChange={handleEditorChange}
                          config={{
                            toolbar: [
                              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                              { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                              { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                              { name: 'colors', items: ['TextColor', 'BGColor'] },
                              { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                            ]
                          }}
                        />
                      </div>
                      <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                    </form>
        </div>
                  <div class="tab-pane" id="account">
                    <div className="mx-auto mt-10 bg-white">
                      <h6 className="text-black text-xl font-semibold mb-4">TERMS AND CONDITIONS</h6>
                      <hr className="mb-6" />
                      <form onSubmit={handleSubmit}>
                        <div className="form-group mb-6">
                          <label htmlFor="terms" className="block text-gray-700 font-medium mb-2">Edit Terms and Conditions</label>
                          <CKEditor
                            data={terms}
                            onChange={handleEditorChange}
                            config={{
                              toolbar: [
                                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                { name: 'colors', items: ['TextColor', 'BGColor'] },
                                { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                              ]
                            }}
                          />
                        </div>
                        <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                      </form>
                    </div>
        </div>
        <div class="tab-pane" id="security">
                    <h6 className="text-black text-xl font-semibold mb-4">PRIVACY POLICY</h6>
                    <hr className="mb-6" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-6">
                        <label htmlFor="policy" className="block text-gray-700 font-medium mb-2">Edit Privacy Policy</label>
                        <CKEditor
                          initData={policy}
                          onChange={handleEditorChange}
                          config={{
                            toolbar: [
                              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                              { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                              { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                              { name: 'colors', items: ['TextColor', 'BGColor'] },
                              { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                            ]
                          }}
                        />
                      </div>
                      <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                    </form>
          <hr/>
        
        </div>
        <div class="tab-pane" id="notification">
                    <h6 className="text-black text-xl font-semibold mb-4">SHIPPING POLICY</h6>
                    <hr className="mb-6" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-6">
                        <label htmlFor="policy" className="block text-gray-700 font-medium mb-2">Edit Shipping Policy</label>
                        <CKEditor
                          initData={policy}
                          onChange={handleEditorChange}
                          config={{
                            toolbar: [
                              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                              { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                              { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                              { name: 'colors', items: ['TextColor', 'BGColor'] },
                              { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                            ]
                          }}
                        />
                      </div>
                      <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                    </form>
        </div>
        <div class="tab-pane" id="billing">
                    <h6 className="text-black text-xl font-semibold mb-4">REFUND POLICY</h6>
                    <hr className="mb-6" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-6">
                        <label htmlFor="policy" className="block text-gray-700 font-medium mb-2">Edit Refund Policy</label>
                        <CKEditor
                          initData={policy}
                          onChange={handleEditorChange}
                          config={{
                            toolbar: [
                              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                              { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                              { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                              { name: 'colors', items: ['TextColor', 'BGColor'] },
                              { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                            ]
                          }}
                        />
                      </div>
                      <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                    </form>
                  </div>
                  <div class="tab-pane" id="banned">
                    <h6 className="text-black text-xl font-semibold mb-4">BANNED ITEMS</h6>
                    <hr className="mb-6" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-6">
                        <label htmlFor="items" className="block text-gray-700 font-medium mb-2">Edit Banned Items</label>
                        <CKEditor
                          initData={items}
                          onChange={handleEditorChange}
                          config={{
                            toolbar: [
                              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
                              { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                              { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },
                              { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                              { name: 'colors', items: ['TextColor', 'BGColor'] },
                              { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
                            ]
                          }}
                        />
                      </div>
                      <button className="btn btn-outline-dark px-4 text-black p-2 rounded-md" type="submit">Save</button>
                    </form>
                  </div>
      </div>
    </div>
  </div>
</div>

</div>
    </div>
    </Container>
  );
};

export default SuggestedCategory;
