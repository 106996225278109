import React from "react";
import { withRouter } from "react-router";
import AuthServices from "../../services/auth.service";

class Login extends React.Component {
  constructor() {
    super();
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  async handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    await AuthServices.login(this.state.username, this.state.password)
      .then((response) => {
        // console.log(response);
        if (response.status == "200") {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.props.history.push("/dashboard");
          window.location.reload();
        } else if (response.status == "201") {
          this.setState({
            loading: false,
            message: "Invalid Credentials",
          });
        } else {
          console.log(response);
          this.setState({
            loading: false,
            message: "No Data Found !",
          });
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage,
        });
      });
  }

  render() {
    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper ">
          <div className="content-wrapper d-flex align-items-center auth px-0 bg-secondary">
            <div className="row w-100 mx-0 ">
              <div className="col-lg-4 mx-auto shadow-lg rounded bg-light">
                <div className="auth-form-light text-left py-4 px-5 ">
                  <div className="brand-logo d-flex align-items-center justify-content-center mb-4">
                    <img
                      src="https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png"
                      alt="logo"
                      style={{ width: '50px', height: 'auto', marginRight: '12px' }}
                    />
                    <h3 className="d-inline my-auto font-weight-bold">Lofaz</h3>
                  </div>
                  <h4 className="mb-3 text-center text-secondary">Hello! Let's get started</h4>
                  <h6 className="font-weight-light mb-4 text-center">Sign in to continue.</h6>
                  <form
                    className="pt-3"
                    onSubmit={this.handleLogin}
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Email"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        required
                      />
                    </div>
                    <div className="mt-4">
                      <button
                        className="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn"
                        disabled={this.state.loading}
                      >
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        SIGN IN
                      </button>
                    </div>
                    {this.state.message && (
                      <div className="form-group mt-3">
                        <div className="alert alert-danger" role="alert">
                          {this.state.message}
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(Login);
