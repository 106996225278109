import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../../utils";
import Container from "../../common/Container";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { API_URL } from "../../../utils";
import _ from 'lodash';

import { saveAs } from "file-saver";

import XLSX from "xlsx";
import AddButton from '../../common/AddButton';

const ViewCustomerButton = ({ viewUrl, handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <a
        className="btn btn-primary p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View"
      >
        <i className="fas fa-eye" />
      </a>
      <button className="btn btn-danger p-2" title="Delete" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
      <button className="btn btn-primary p-2" title="Edit" onClick={handleEdit}>
        <i className="fas fa-pen" />
      </button>
    </div>
  </div>
);


export const getCustomerTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}customer/admin/`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctlyyy", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], total_customer: 0 };
  }
};



class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      featuredOnly: false
      // searchBy: 'title', // Default searchBy field set to 'title' (Customer Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getCustomerTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetch", data);

        this.setState({ data: data.data, totalRows: data.total_customer, fetched: true });

      console.log("data", this.state.data);

    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };

  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const limit = parseInt(searchParams.get('limit')) || 10;
    const search = searchParams.get('search') || '';
    const sort = parseInt(searchParams.get('sortBy')) || -1;
    const sortBy = searchParams.get('sort') || 'createdAt';

    return { currentPage, limit, search, sort, sortBy };
  }

  downloadAsXls = () => {
    const { data } = this.state;
    const columns = this.columns.map(col => ({ name: col.name, selector: col.selector }));
    const tableName = 'VendorData'; // Customize this as needed
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: tableName,
      Subject: "Exported Data",
      Author: "Your Company",
      CreatedDate: new Date(),
    };
    wb.SheetNames.push("Sheet1");
    // Prepare header
    const header = columns.map(col => col.name);
    const ws_data = [header];
    // Prepare data rows
    data.forEach(row => {
      const rowData = columns.map(col => row[col.selector] || ''); // Adjust based on your data structure
      ws_data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Sheet1"] = ws;
    // Convert to binary string and save
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${tableName}_${new Date().toISOString().slice(0, 10)}.xlsx`
    );
  };


  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("on page change:", currentPage, limit, search, sort, sortBy);
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  toggleFeaturedFilter = () => {
    this.setState(prevState => ({
      featuredOnly: !prevState.featuredOnly
    }));
  }

  updateActivate = async (status, id) => {
    try {
      const authHeaderObject = authHeader(); // Assuming this returns the necessary headers
      const response = await axios.patch(`${API_URL}vendor/activate/${id}`, { activate: status }, {
        headers: {
          ...authHeaderObject,
          'Authorization': 'Bearer YOUR_AUTH_TOKEN_HERE' // Replace with dynamic token retrieval
        }
      });

      //console.log(response.data); // Log the response data for debugging purposes
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    } catch (err) {
      console.error('Error updating activation status:', err);
      // Handle the error appropriately, maybe show a notification to the user
    }
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
      this.updateURLParams();
    });
  }

  columns = [
    {
      name: "SL",
      cell: (row, index) => <p className='text-center'>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>,
      width: '100px'
    },
    {
      name: "Customer Name",
      cell: (row) => {
        const { name } = row.profile || {};
        const firstName = name?.first || "";
        const lastName = name?.last || "";
        return firstName || lastName ? `${firstName} ${lastName}` : "N/A";
      },
      minWidth: '150px'
    },
    {
      name: "Mobile Number",
      cell: (data) => (
        <p>{data.profile[0] ? data.profile[0].phone : 'NI'}</p>
      ),
      width: '300px',
      // sortField: 'createdAt',
      // sortable: true,
    },
    {
      name: "Total Orders", cell: (data) => {
        //console.log(data);
        return (<p>{data.total_order}</p>)
      }, width: '150px',
      sortField: 'total_order',
      sortable: true,
    },
    {
      name: "Last Order", cell: (data) => {
        //console.log(data);
        return (<p>{data["total_product"]}</p>)
      }, width: '150px'
    },
    {
      name: "Vendor Count", cell: (data) => {
        //console.log(data);
        return (<p>{data.total_shop}</p>)
      }, width: '150px',
      sortField: 'total_shop',
      sortable: true,
    },

    {
      name: "Wishlist Count", cell: (data) => {
        //console.log(data);
        return (<p>{data.total_wishlist}</p>)
      }, width: '150px',
      sortField: 'total_wishlist',
      sortable: true,
    },
    {
      name: "Cart Count", cell: (data) => {
        //console.log(data);
        return (<p>{data.total_cart}</p>)
      }, width: '150px',
      sortField: 'total_cart',
      sortable: true,
    },
    {
      name: "Created On",
      selector: (data) => {
        const date = new Date(data.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'createdAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      button: true,
      minWidth: '200px',
      // cell: (row) => (
      //   <div onClick={() => this.handleSortClick('createdAt')}>
      //     {new Date(row.createdAt).toLocaleString('en-US', {
      //       weekday: 'short',
      //       month: 'short',
      //       day: '2-digit',
      //       year: 'numeric',
      //       hour: 'numeric',
      //       minute: '2-digit',
      //       hour12: true
      //     }).replace(",", "")}
      //   </div>
      // ),
    },
    {
      name: "Last Login",
      cell: (data) => {
        const date = new Date(data.updatedAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      sortField: 'updatedAt',
      sortable: true,
    },
    // {
    //   name: "Status",
    //   cell: (data) => (
    //     <StatusSwitch
    //       checked={data.active}
    //       onChange={(event) => this.handleStatusChange(data._id, event.target.checked)}
    //     />
    //   ),
    //   width: '150px'
    // },
    {
      name: 'Actions',
      cell: (data) => (
        <div style={{ gap: "7px" }} className="d-flex">
          <ViewCustomerButton
            viewUrl={`/customer/${data._id}`}
            handleDelete={() =>
              handleDelete(
                data["_id"],
                "Customer",
                async () => {
                  const { currentPage, limit } = this.state;
                  const data = await getCustomerTableConfig(currentPage, limit);
                  this.setState({ data: data.catalog, totalRows: data.total_customer });
                }
              )
            }
          />
        </div>
      ),
    }
  ];


  // handleStatusChange = async (id, newStatus) => {
  //   // Make API call to update the status on the server
  //   try {
  //     await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
  //       headers: authHeader(),
  //     });
  //     const updatedData = this.state.data.map(item =>
  //       item._id === id ? { ...item, active: newStatus } : item
  //     );
  //     this.setState({ data: updatedData });
  //   } catch (error) {
  //     console.error("Error updating catalog status:", error);
  //   }
  // };

  render() {
    const { data, fetched, currentPage, search, limit, totalRows } = this.state;
    const { history } = this.props;
    // // Apply the filtering here based on featuredOnly state
    // const filteredData = this.state.featuredOnly ? data.filter(item => item.activate===this.state.featuredOnly) : data;
    // console.log("filter",filteredData);
    console.log("order", data);





    return (
      <Container>

        <div className="mb-3 d-flex mt-4 justify-content-between text-center">
          <div className="mx-2 mt-2"><h3>Customers</h3></div>
          <div className='d-flex'>
            <div className="flex-grow">
              <input
                type="text"
                className="form-control"
                aria-label="select search by"
                placeholder="Search"
                value={search}
                onChange={this.handleSearchChange}
              />
            </div>
            <button className="btn btn-dark mx-2" onClick={this.downloadAsXls}>
              <i className="fa fa-file-download" />
            </button>
          </div>
          {/* <AddButton label="New Customer" handler={() => history.push('customer/new')} /> */}
        </div>
        <DataTable
          title=""
          // searchByList={[
          //   { label: "Catalog Name", value: "title" },

          //   // {
          //   //   label: "Vendor Business Name",
          //   //   value: ({ data }) => (data ? data["shop"]["business_name"] : ""),
          //   // },
          // ]}
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Customer Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ data }) => (data ? data["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getCustomerTableConfig(history)}


          data={data}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
          paginationTotalRows={totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}

        />
      </Container>
    );
  }
}

export default Customer;
