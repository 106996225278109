import React from "react";
import { Link } from "react-router-dom";
import './Sidebar.css'; // Import the CSS file for the sidebar

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: null
    };
  }

  handleCollapse = (sectionId) => {
    this.setState(prevState => ({
      openSection: prevState.openSection === sectionId ? null : sectionId
    }));
  }

  render() {
    const { openSection } = this.state;

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="fas fa-tachometer-alt-slowest menu-icon" />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/vendor">
              <i className="fa fa-user menu-icon" aria-hidden="true" />
              <span className="menu-title">Vendors</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/order">
              <i className="fas fa-shopping-cart menu-icon" aria-hidden="true" />
              <span className="menu-title">Orders</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/customer">
              <i className="fa fa-users menu-icon" aria-hidden="true" />
              <span className="menu-title">Customers</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/banner">
              <i className="fas fa-tachometer-alt-slowest menu-icon banner-icon" />
              <span className="menu-title">Banner</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/product">
              <i className="fa fa-shopping-bag menu-icon" aria-hidden="true" />
              <span className="menu-title">Products</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/catalog">
              <i className="fa fa-list-ul menu-icon" aria-hidden="true" />
              <span className="menu-title">Catalogs</span>
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#marketing"
              onClick={() => this.handleCollapse('marketing')}
            >
              <i className="icon-layout menu-icon fas fa-bullhorn"></i>
              <span className="menu-title">Marketing</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openSection === 'marketing' ? 'show' : ''}`} id="marketing">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/notification">
                    <i className="fa fa-bell menu-icon" aria-hidden="true" />
                    <span className="menu-title">Notification</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/category">
                    <i className="fa fa-th-large menu-icon" aria-hidden="true" />
                    <span className="menu-title">Category</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/notifications">
                    <i className="fa fa-list menu-icon" aria-hidden="true" />
                    <span className="menu-title">Sub Category</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/post">
                    <i className="fa fa-window-restore menu-icon" aria-hidden="true" />
                    <span className="menu-title">Post</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/story">
                    <i className="fa fa-circle-notch menu-icon" aria-hidden="true" />
                    <span className="menu-title">Story</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#location"
              onClick={() => this.handleCollapse('location')}
            >
              <i className="icon-layout menu-icon fas fa-map-marker-alt"></i>
              <span className="menu-title">Location</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openSection === 'location' ? 'show' : ''}`} id="location">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/pincode">
                    <i className="fa fa-map-pin menu-icon" aria-hidden="true" />
                    <span className="menu-title">Pincode</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/city">
                    <i className="fa fa-city menu-icon" aria-hidden="true" />
                    <span className="menu-title">City</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/state">
                    <i className="fa fa-map menu-icon" aria-hidden="true" />
                    <span className="menu-title">State</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/country">
                    <i className="fa fa-globe menu-icon" aria-hidden="true" />
                    <span className="menu-title">Country</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#suggested"
              onClick={() => this.handleCollapse('suggested')}
            >
              <i className="icon-layout menu-icon"></i>
              <span className="menu-title">Suggested</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openSection === 'suggested' ? 'show' : ''}`} id="suggested">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/suggested/category">
                    <i className="fa fa-th-large menu-icon" aria-hidden="true" />
                    <span className="menu-title">Category</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/suggested/subcategory">
                    <i className="fa fa-list menu-icon" aria-hidden="true" />
                    <span className="menu-title">Sub Category</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/suggested/product">
                    <i className="fa fa-shopping-bag menu-icon" aria-hidden="true" />
                    <span className="menu-title">Products</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#settings"
              onClick={() => this.handleCollapse('settings')}
            >
              <i className="fa fa-cog menu-icon"></i>
              <span className="menu-title">Settings</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openSection === 'settings' ? 'show' : ''}`} id="settings">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/basic">
                    <i className="fa fa-user-tag menu-icon" aria-hidden="true" />
                    <span className="menu-title">General</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/vendor-category">
                    <i className="fa fa-user-tag menu-icon" aria-hidden="true" />
                    <span className="menu-title">Vendor Category</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/units">
                    <i className="fa fa-cubes menu-icon" aria-hidden="true" />
                    <span className="menu-title">Units</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="">
                    <i className="fa fa-history menu-icon" aria-hidden="true" />
                    <span className="menu-title">Change Log</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/suggested/category">
                    <i className="fa fa-th-large menu-icon" aria-hidden="true" />
                    <span className="menu-title">Policy</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Sidebar;
