import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import { checkAuthed, handleDelete } from "../../utils";
import Container from "../common/Container";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { API_URL } from "../../utils";
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';
import { APP_URL } from '../../utils';
import AddButton from '../common/AddButton';

const ViewCatalogButton = ({ viewUrl, handleEdit, handleDelete }) => (
  <div className="container">
    <div className="d-flex" style={{ gap: "7px" }}>
      <a
        className="btn btn-primary p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View"
      >
        <i className="fas fa-eye" />
      </a>
      <button className="btn btn-danger p-2" title="Delete" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
      <button className="btn btn-primary p-2" title="Edit" onClick={handleEdit}>
        <i className="fas fa-pen" />
      </button>
    </div>
  </div>
);

const StatusSwitch = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      color="primary"
    />
  );
};

export const getProductTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const authHeaderObject = authHeader();
    const response = await axios.get(`${API_URL}product/admin`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        ...authHeaderObject,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTk0NDEyZGVhYzNjYzRmNTVlYjMwMjIiLCJpYXQiOjE3MTgyNzg0NzYsImV4cCI6MTc0OTgxNDQ3Nn0.jK6-AUgAQhPxcbpcBEQ0H2qGG_9vVbFH12OCSdFiaB0'
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], total_count: 0 };
  }
};



class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      // searchBy: 'title', // Default searchBy field set to 'title' (Product Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
    const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const limit = parseInt(searchParams.get('limit')) || 10;
    const search = searchParams.get('search') || '';
    const sort =  searchParams.get('sort') || 'createdAt';
    const sortBy = parseInt(searchParams.get('sortBy')) || -1;

    return { currentPage, limit, search, sort, sortBy };
  }

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort : sort ,
      sortBy
    }).toString();
    this.props.history.push(`?${queryParams}`);
  };

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getProductTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetch", data);
      this.setState({ orderData: data.data, totalRows: data.total_count, fetched: true });
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
    });
    this.updateURLParams();
  };

 

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
    this.updateURLParams();
  };

  handleSortClick = (column) => {
    this.setState({sort : column})
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
    this.updateURLParams();
    console.log("state",this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
  }


  handleStatusChange = async (id, newStatus) => {
    // Make API call to update the status on the server
    try {
      await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
        headers: authHeader(),
      });
      const updatedData = this.state.orderData.map(item =>
        item._id === id ? { ...item, active: newStatus } : item
      );
      this.setState({ orderData: updatedData });
    } catch (error) {
      console.error("Error updating catalog status:", error);
    }
  };

  columns = [
    { name: "SL", cell: (row, index) => <p className='text-center'>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '100px' },

    {
      name: 'Logo',
      cell: (data) => (
        <img
          src={
            data
              ? data.featured_img
              : 'https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png'
          }
          style={{ width: "50%", height: "50%" }}
        />
      ),
      minWidth: '100px',
    },
    {
      name: "Product Name", cell: (data) => {
        //console.log(data);
        return (<p>{data.title ? data.title : ''}</p>)
      }, width: '300px',
      sortField: 'title',
      sortable: true,
    },
    {
      name: "Business Name", cell: (data) => {
        //console.log(data);
        return (<p>{data["shop"].business_name ? data["shop"].business_name : ''}</p>)
      }, width: '150px'
    },
    {
      name: "Catalog", cell: (data) => {
        //console.log(data);
        return (<p>{data.catalog.title ? data.catalog.title : ''}</p>)
      }, width: '150px'
    },
    {
      name: "No of Units", cell: (data) => {
        //console.log(data);
        return (<p>{data.unit_count ? data.unit_count : ''}</p>)
      }, width: '150px', sortField: 'unit_count',
      sortable: true,
    },
    {
      name: "Unit Type", cell: (data) => {
        //console.log(data);
        return (<p>{data.trends ? data.trends : ''}</p>)
      }, width: '150px'
    },
    {
      name: "MRP", cell: (data) => {
        //console.log(data);
        return (<p>{data.mrp ? data.mrp : ''}</p>)
      }, width: '150px',
      sortField: 'mrp',
      sortable: true,
    },
    {
      name: "Sale Price", cell: (data) => {
        //console.log(data);
        return (<p>{data.sales_price ? data.sales_price : ''}</p>)
      }, width: '150px',
      sortField: 'sales_price',
      sortable: true,
    },
    {
      name: "Stock", cell: (data) => {
        //console.log(data);
        return (<p>{data["total_product"] ? data["total_product"] : ''}</p>)
      }, width: '150px'
    },
    {
      name: "Product Details", cell: (data) => {
        //console.log(data);
        return (<p>{data.desc ? data.desc : ''}</p>)
      }, width: '150px',
      sortField: 'desc',
      sortable: true,
    },
    {
      name: "Label", cell: (data) => {
        //console.log(data);
        return (<p>{data["total_product"] ? data["total_product"] : ''}</p>)
      }, width: '150px'
    },
    {
      name: "Created On",
      selector: (data) => {
        const date = new Date(data.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return formattedDate;
      },
      minWidth: '200px',
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: 'createdAt',
      sortable: true,
      // sortFunction: {this.handleSortClick('createdAt')}}
      button: true,
      minWidth: '200px',
      // cell: (row) => (
      //   <div onClick={() => this.handleSortClick('createdAt')}>
      //     {new Date(row.createdAt).toLocaleString('en-US', {
      //       weekday: 'short',
      //       month: 'short',
      //       day: '2-digit',
      //       year: 'numeric',
      //       hour: 'numeric',
      //       minute: '2-digit',
      //       hour12: true
      //     }).replace(",", "")}
      //   </div>
      // ),
    },
    {
      name: 'Actions',
      cell: (data) => (
        <div style={{ gap: "7px" }} className="d-flex">
          <ViewCatalogButton
            viewUrl={APP_URL + data.url}
            handleDelete={() =>
              handleDelete(
                data._id,
                "Catalog",
                async () => {
                  const { currentPage, limit } = this.state;
                  const data = await getProductTableConfig(currentPage, limit);
                  this.setState({ orderData: data.catalog, totalRows: data.total_count });
                }
              )
            }
          />
        </div>
      ),
    }
  ];

  render() {
    const { orderData, fetched, currentPage, search, limit, totalRows } = this.state;

    const { history } = this.props;

    console.log("order",orderData);


    

    return (
      <Container>

        <div className="mb-3 d-flex mt-4 justify-content-between align-items-center text-center">
          <div><h3>Products</h3></div>
          <div className="col-12 col-xl-5 col-md-3">
            <input
              type="text"
              className="form-control"
              aria-label="select search by"
              placeholder="Search by product name"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>
          
          
          
          
        

          
        </div>
        <DataTable
          title=""
          searchByList={[
            { label: "Catalog Name", value: "title" },

            // {
            //   label: "Vendor Business Name",
            //   value: ({ data }) => (data ? data["shop"]["business_name"] : ""),
            // },
          ]}
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ data }) => (data ? data["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getProductTableConfig(history)}


          data={orderData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
          paginationTotalRows={totalRows} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
      </Container>
    );
  }
}

export default Product;
