import React from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../../../services/user.service";
import { ModalForm } from "./ModalForm";

export function NewSuggestedProductModal({ show, onHide, preFill }) {
  const [actionLoading, setActionLoading] = React.useState(true);
  const [catList, setCatList] = React.useState([]);
  const [subCatList, setSubCatList] = React.useState({});
  var obj = {};
  React.useEffect(() => {
    console.log("hochhe");
    UserService.get
      .SuggestedCategoryList()
      .then((res) => res.data)
      .then(({ data: catList }) => {
        const allPromise = catList.map(
          (cat) =>
            new Promise((res, rej) => {
              UserService.get
                .SubCategoryInCategory(cat._id)
                .then((res) => (res.status === 200 ? res.data : { data: [] }))
                .then(({ data: subCat }) => res((obj[`${cat.name}`] = subCat)))
                .catch(rej);
            })
        );
        Promise.allSettled(allPromise)
          .then(() => {
            console.log(obj);
            setSubCatList(obj);
            setCatList(catList);
            setActionLoading(false);
          })
          .catch(console.log);
      });
  }, []);
  return (
    !actionLoading && (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {preFill ? "Edit" : "Add New"} Suggested Product
          </Modal.Title>
        </Modal.Header>
        {actionLoading ? (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        ) : (
          <ModalForm
            onHide={onHide}
            preFill={preFill}
            catList={catList}
            subCatList={subCatList}
            submitHandler={
              UserService[preFill ? "put" : "post"].SuggestedProduct
            }
          />
        )}
      </Modal>
    )
  );
}
