import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

class Container extends Component {
  render() {
    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper pt-4 vendor-out-container">{this.props.children}</div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Container;
